import { FC } from 'react';
import TableView from 'src/components/0500_blueprints/dystopia_rising/TableView';
import useOrganization from 'src/hooks/organizations/useOrganization';
import usePermission from 'src/hooks/permissions/usePermissions';

const Tabular: FC = () => {
  const { organization } = useOrganization();
  const { isPermitted: canUseDepository } = usePermission({
    action: 'create_item',
  });

  if ( !canUseDepository ) return null;

  switch ( organization?.slug ) {
    case 'dystopia-rising':
      return <TableView />;
    default:
      return null;
  }
};

export default Tabular;
