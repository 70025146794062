import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import { IAttendeeShiftStatusEnum } from 'src/graphql/types';
import { TShift } from '../../ShiftKind/Shift';

interface IProps {
  shift: TShift;
}
const ShiftGroup: FC<IProps> = ({ shift }) => {
  if ( shift.playersCount === 0 ) return null;

  return (
    <div>
      <div>{shift.label}</div>
      <div className="grid gap-1 pt-1">
        {shift.attendeeShifts
          ?.sort(( a, b ) => a.player.id - b.player.id )
          .map( x => (
            <div key={x.id} className="flex gap-4 items-center pl-4">
              <FontAwesomeIcon
                icon={
                  x.status === IAttendeeShiftStatusEnum.Fulfilled
                    ? faCheck
                    : faSquare
                }
                className="fa-fw text-juno-gray-50"
              />
              <div className="w-16 text-right">{x.player.id}</div>
              <div>{x.player.fullName}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShiftGroup;
