import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import Root from './routes/Root';
import Error from './pages/Error';
import Events from './routes/Organization/Events';
import Event from './routes/Organization/Events/Event';
import Character from './routes/Organization/Player/Character/DystopiaRising';
import Player from './routes/Organization/Player';
import WelcomePage from './pages/WelcomePage';
import Bio from './routes/Organization/Player/Bio';
import Memberships from './routes/Organization/Player/Memberships';
import OrganizationMemberhips from './routes/Organization/Memberships';
import 'react-toggle/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/react-toggle.scss';
import './css/react-datepicker.scss';
import './css/rc-slider.scss';
import PlayerAttendance from './routes/Organization/Player/Attendance';
import Perks from './routes/Organization/Player/Perks';
import PlayerCorrectiveActions from './routes/Organization/Player/CorrectiveActions';
import Referrals from './routes/Organization/Player/Referrals';
import Build from './routes/Organization/Player/Character/DystopiaRising/Build';
import EventBasics from './routes/Organization/Events/Event/Basics';
import Invoice from './routes/Organization/Invoice';
import Tickets from './routes/Organization/Events/Event/Tickets';
import Characters from './routes/Organization/Player/Characters';
import Shifts from './routes/Organization/Events/Event/Shifts';
import Checkin from './routes/Organization/Events/Event/Checkin';
import XpAudit from './routes/Organization/Player/Character/DystopiaRising/XpAudit';
import Printout from './routes/Organization/Player/Character/DystopiaRising/Printout';
import Checkout from './routes/Organization/Events/Event/Checkout';
import Printouts from './routes/Organization/Events/Event/Printouts';
import Journal from './routes/Organization/Player/Character/DystopiaRising/Journal';
import Fellowships from './routes/Organization/Fellowships';
import OrganizationCaps from './routes/Organization/Caps';
import { IFellowshipKindEnum } from './graphql/types';
import OrganizationCorrectiveActions from './routes/Organization/CorrectiveActions';
import Blueprints from './routes/Organization/Blueprints';
import Tabular from './routes/Organization/Blueprints/Tabular';
import BranchBasics from './routes/Organization/Branches/Branch/Basics';
import BranchLocations from './routes/Organization/Branches/Branch/BranchLocations';
import Branch from './routes/Organization/Branches/Branch';
import BranchAttendance from './routes/Organization/Branches/Branch/Attendance';
import Branches from './routes/Organization/Branches';
import BranchCorrectiveActions from './routes/Organization/Branches/Branch/CorrectiveActions';
import BranchCaps from './routes/Organization/Branches/Branch/Caps';
import EventBlueprints from './routes/Organization/Events/Event/Blueprints';
import RebirthedCharacters from './routes/Organization/RebirthedCharacters';
import SpecialDates from './routes/Organization/SpecialDates';

Sentry.init({
  dsn: 'https://69ab4c336e859fb626a5544cfb3cfde3@o4508236688064512.ingest.us.sentry.io/4508236689637376',
  integrations: [],
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/events" element={<Events />} />
      <Route path="/:organizationSlug/characters" element={<Build />} />
      <Route path="/:organizationSlug">
        <Route index element={<Branches />} />
        <Route path="branches">
          <Route index element={<Branches />} />
          <Route path=":branchId" element={<Branch />}>
            <Route index element={<BranchBasics />} />
            <Route path="basics" element={<BranchBasics />} />
            <Route path="locations" element={<BranchLocations />} />
            <Route path="attendance" element={<BranchAttendance />} />
            <Route path="caps" element={<BranchCaps />} />
            <Route
              path="corrective_actions"
              element={<BranchCorrectiveActions />}
            />
          </Route>
        </Route>

        <Route path="blueprints">
          <Route
            index
            element={
              <Blueprints
                components={{ withItemCreation: true, withTitle: true }}
              />
            }
          />
          <Route path="tabular" element={<Tabular />} />
          <Route
            path=":itemId"
            element={
              <Blueprints
                components={{ withItemCreation: true, withTitle: true }}
              />
            }
          />
        </Route>
        <Route path="invoice" element={<Invoice />} />
        <Route path="settings">
          <Route path="special_dates" element={<SpecialDates />} />
        </Route>
        <Route path="mechanics">
          <Route
            index
            element={<Fellowships type={IFellowshipKindEnum.Disease} />}
          />
          <Route path="diseases">
            <Route
              index
              element={<Fellowships type={IFellowshipKindEnum.Disease} />}
            />
            <Route
              path=":fellowshipId"
              element={<Fellowships type={IFellowshipKindEnum.Disease} />}
            />
          </Route>
          <Route path="societies">
            <Route
              index
              element={<Fellowships type={IFellowshipKindEnum.Society} />}
            />
            <Route
              path=":fellowshipId"
              element={<Fellowships type={IFellowshipKindEnum.Society} />}
            />
          </Route>
        </Route>

        <Route path="reports">
          <Route
            path="character_retirements"
            element={<RebirthedCharacters />}
          />
          <Route path="corrective_actions">
            <Route index element={<OrganizationCorrectiveActions />} />
            <Route
              path=":branchId"
              element={<OrganizationCorrectiveActions />}
            />
          </Route>
          <Route path="memberships" element={<OrganizationMemberhips />} />
          <Route path="caps">
            <Route index element={<OrganizationCaps />} />
            <Route path=":branchId" element={<OrganizationCaps />} />
          </Route>
          <Route index element={<OrganizationMemberhips />} />
        </Route>
        <Route path="events" element={<Events />} />
        <Route path="events/:eventId" element={<Event />}>
          <Route path="basics" element={<EventBasics />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="shifts" element={<Shifts />} />
          {/* <Route path="mods" element={<div>Mods</div>} /> */}
          <Route path="checkin" element={<Checkin />} />
          <Route path="printouts" element={<Printouts />} />
          <Route path="blueprints">
            <Route index element={<EventBlueprints />} />
            <Route path=":itemId" element={<EventBlueprints />} />
          </Route>
          <Route path="checkout" element={<Checkout />} />
          <Route index element={<EventBasics />} />
        </Route>
        <Route path="players/:playerId">
          <Route path="characters">
            <Route index element={<Characters />} />
            <Route path=":characterId">
              <Route element={<Character />}>
                <Route index element={<Build />} />
                <Route path="build" element={<Build />} />
                <Route path="journal" element={<Journal />} />
                <Route path="xp_audit" element={<XpAudit />} />
                <Route path="printout" element={<Printout />} />
                {/* <Route path="inventory" element={<Inventory />} /> */}
              </Route>
            </Route>
          </Route>
          <Route element={<Player />}>
            <Route path="bio" element={<Bio />} />
            <Route path="attendance" element={<PlayerAttendance />} />
            <Route path="memberships" element={<Memberships />} />
            <Route path="perks" element={<Perks />} />
            <Route
              path="corrective_actions"
              element={<PlayerCorrectiveActions />}
            />
            <Route path="referrals" element={<Referrals />} />
            <Route
              path="corrective_actions"
              element={<div>Corrective Actions</div>}
            />
            <Route index element={<Bio />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

ReactDOM.createRoot( document.getElementById( 'root' )! ).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
