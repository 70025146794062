import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/0100_button';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useItem from 'src/hooks/items/useItem';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useFormContext } from 'react-hook-form';
import Input from 'src/components/0100_input';
import usePrintability from '../../hooks/usePrintability';

const PrintControl: FC = () => {
  const { eventId, itemId } = useParams();
  const { register, setValue } = useFormContext();
  const { isPermitted: canCreateItem } = usePermission({
    action: 'create_item',
  });
  const { item } = useItem({ itemId: Number( itemId ) });
  const { message } = usePrintability({ item });
  const { buttonState } = useButtonStates();

  if ( !eventId && !canCreateItem ) return null;

  return (
    <div className="gray-box border border-juno-gray-700 p-2">
      <div className="flex items-center justify-end gap-2 w-full">
        <div className="flex items-center gap-2">
          Copies
          <div className="w-12 flex items-center gap-2">
            <Input
              fullWidth
              className="text-right"
              type="number"
              {...register( 'copies' )}
              onFocus={x => x.target.select()}
              onChange={x =>
                setValue(
                  'copies',
                  Math.max( 1, Math.min( 32, Number( x.target.value ))),
                  {
                    shouldDirty: true,
                  },
                )
              }
            />
          </div>
        </div>
        <div className="w-24">
          <Button
            defaultLabel="Print"
            className="w-full"
            state={buttonState({
              isHighlight: true,
              isValid: message === null,
              isDirty: true,
            })}
            onClick={() => window.print()}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintControl;
