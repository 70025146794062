import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { startCase } from 'lodash';
import { IEventConfig } from 'src/graphql/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Ticket, { TTicket } from './Ticket';
import NewTicket from './NewTicket';
import { TOnFilterChange } from './Ticket/TicketAttendees';

interface IProps {
  expandedTicketIds: number[];
  name: string;
  adjustableShift: boolean;
  config?: IEventConfig;
  tickets: TTicket[];
  onExpand: ( id: number ) => void;
  onFilterChange?: ({
    identifier,
    length,
    hasSearchQuery,
  }: TOnFilterChange ) => void;
}

const boxStyleMap: Record<string, string> = {
  new_player_restricted: 'green-box',
  unrestricted: 'cyan-box',
  assistant_restricted: 'orange-box',
  employee_restricted: 'purple-box',
};

const borderColorMap: Record<string, string> = {
  new_player_restricted: 'border-juno-green-200',
  unrestricted: 'border-juno-cyan-200',
  assistant_restricted: 'border-juno-orange-200',
  employee_restricted: 'border-juno-purple-200',
};

const TicketGroup: FC<IProps> = ({
  expandedTicketIds,
  name,
  adjustableShift,
  config,
  tickets,
  onExpand,
  onFilterChange,
}) => {
  const [ isNewTicketPanelOpen, setIsNewTicketPanelOpen ] = useState( false );
  const [ hasSearchQuery, setHasSearchQuery ] = useState( false );
  const [ searchMatches, setSearchMatches ] = useState<{ [key: string]: number }>(
    {},
  );
  const filteredAttendeesLength = useMemo(
    () => Object.values( searchMatches ).reduce(( a, b ) => a + b, 0 ),
    [ searchMatches ],
  );
  const hasAttendees =
    !hasSearchQuery || ( hasSearchQuery && filteredAttendeesLength > 0 );
  const niceName = startCase( name ).replace( 'Restricted', '' );
  const handleFilterChange = useCallback(
    ({ identifier, length, hasSearchQuery }: TOnFilterChange ) => {
      setHasSearchQuery( hasSearchQuery );
      setSearchMatches( x => ({
        ...x,
        [identifier ?? '']: length,
      }));
    },
    [],
  );

  useEffect(() => {
    onFilterChange?.({
      identifier: name,
      length: filteredAttendeesLength,
      hasSearchQuery,
    });
  }, [ filteredAttendeesLength, hasSearchQuery, name, onFilterChange ]);

  return (
    <>
      <tr
        className={clsx(
          hasAttendees ? 'saturate-100 opacity-100' : 'saturate-50 opacity-50',
        )}
      >
        <td colSpan={4}>
          <button
            type="button"
            className={clsx(
              'py-2 px-4 flex items-center justify-between w-full',
              boxStyleMap[name] ?? 'midtone-box',
            )}
            onClick={() => setIsNewTicketPanelOpen( x => !x )}
          >
            <div>{niceName === 'Assistant ' ? 'Guide' : niceName}</div>
            <FontAwesomeIcon
              icon={faPlus}
              className={clsx(
                'transition-all',
                isNewTicketPanelOpen && 'rotate-[225deg]',
              )}
            />
          </button>
          <div
            className={clsx(
              'grid transition-all duration-300 mb-2 gray-box',
              borderColorMap[name] ?? 'border-juno-gray-700',
              isNewTicketPanelOpen
                ? 'grid-rows-[1fr] border-b-2'
                : 'grid-rows-[0fr] border-b-0',
            )}
          >
            <div className="overflow-hidden">
              <NewTicket
                adjustableShift={adjustableShift}
                config={config}
                name={name}
              />
            </div>
          </div>
        </td>
      </tr>

      {tickets.length > 0 ? (
        tickets
          .sort(( a, b ) => a.id - b.id )
          .map( ticket => (
            <Ticket
              key={ticket.id}
              ticket={ticket}
              isExpanded={expandedTicketIds.includes( ticket.id )}
              onExpand={() => onExpand( ticket.id )}
              onFilterChange={handleFilterChange}
            />
          ))
      ) : (
        <tr>
          <td colSpan={4} className="pb-2 px-2 text-juno-gray-700">
            No Tickets Available
          </td>
        </tr>
      )}
    </>
  );
};

export default TicketGroup;
