import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import {
  destroyCharacter,
  retireCharacter,
  updateCharacter,
} from 'src/graphql/mutations/characters.graphql';
import {
  IDestroyCharacterMutation,
  IDestroyCharacterMutationVariables,
  IRetireCharacterMutation,
  IRetireCharacterMutationVariables,
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { ICharacter, ICharacterStatusEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useCharacterRetirementPaths from 'src/hooks/characters/useCharacterRetirementPaths';
import { useMutation } from 'urql';

interface IProps {
  character: Pick<
    ICharacter,
    'id' | 'name' | 'status' | 'buildEarned' | 'buildUsed'
  >;
  stale: boolean;
  canDestroyCharacter?: boolean;
  canChangeCharacterStatus?: boolean;
  canRetireCharacter?: boolean;
  canActivate: boolean;
  branch?: string;
}

const CharacterRow: FC<IProps> = ({
  character,
  stale,
  canDestroyCharacter,
  canChangeCharacterStatus,
  canRetireCharacter,
  canActivate,
  branch,
}) => {
  const { playerId } = useParams();
  const { linkToCharacter } = useAppNavigations();
  const [ isDestroying, setIsDestroying ] = useState( false );
  const [ showDestroyConfirmation, setShowDestroyConfirmation ] = useState( false );
  const [ showRetirementConfirmation, setShowRetirementConfirmation ] =
    useState( false );
  const [ isActionsExpanded, setIsActionsExpanded ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null );
  const {
    alive,
    retirementPaths,
    refetch: refetchRetirementPaths,
  } = useCharacterRetirementPaths({
    playerId: Number( playerId ),
    characterId: character.id,
    enabled: isActionsExpanded,
  });
  const [ , destroy ] = useMutation<
    IDestroyCharacterMutation,
    IDestroyCharacterMutationVariables
  >( destroyCharacter );
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >( updateCharacter );
  const [ retireResult, retire ] = useMutation<
    IRetireCharacterMutation,
    IRetireCharacterMutationVariables
  >( retireCharacter );
  const shouldDisplayActions = useMemo(() => {
    switch ( character.status ) {
      case ICharacterStatusEnum.Active:
        return canChangeCharacterStatus;
      case ICharacterStatusEnum.Staged:
        return true;
      default:
        return false;
    }
  }, [ canChangeCharacterStatus, character.status ]);

  useEffect(() => {
    if ( !isActionsExpanded ) {
      setShowDestroyConfirmation( false );
      setShowRetirementConfirmation( false );
      setErrorMessage( null );
    }
  }, [ isActionsExpanded, refetchRetirementPaths ]);

  if ( isDestroying ) return null;

  return (
    <div className="border border-juno-gray-700 rounded hover:border-juno-gray-200 hover:text-shadow transition-all">
      <div
        className={clsx(
          'flex justify-between items-end gray-box-angled p-2  group',
          ( stale || updateResult.fetching ) && 'blur-xs',
        )}
      >
        <Link
          className="w-full"
          to={linkToCharacter({
            playerId: Number( playerId ),
            characterId: character.id,
          })}
        >
          <div>
            <div>{character.name}</div>
            <div>
              XP: {character.buildUsed}/{character.buildEarned}
            </div>
          </div>
        </Link>
        {shouldDisplayActions && (
          <div className="flex items-center gap-2 w-36 text-sm opacity-50 group-hover:opacity-100 transition-all">
            <div className="w-full">
              <button
                type="button"
                className="w-full flex justify-end items-center gap-2 text-right"
                onClick={e => {
                  e.preventDefault();
                  setIsActionsExpanded( x => !x );
                }}
              >
                Actions
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={clsx(
                    'transition-all duration-300',
                    isActionsExpanded && '-scale-y-100',
                  )}
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <div
        className={clsx(
          'grid transition-all duration-300',
          isActionsExpanded ? 'grid-rows-[1fr] py-2' : 'grid-rows-[0fr] py-0',
        )}
      >
        <div className="overflow-hidden grid gap-2">
          {character.status === ICharacterStatusEnum.Active && (
            <button
              type="button"
              className="p-2 text-left bg-transparent border border-juno-gray-700 mx-2 rounded opacity-75 hover:opacity-100 transition-all disabled:opacity-50 "
              disabled={!canChangeCharacterStatus}
              onClick={() => {
                setErrorMessage( null );
                update({
                  characterId: character.id,
                  status: ICharacterStatusEnum.Staged,
                }).then( res => {
                  if ( res.data?.updateCharacterData?.error ) {
                    setErrorMessage( res.data.updateCharacterData.error );
                  }
                });
              }}
            >
              <div>Stage</div>
              <div className="text-sm opacity-75">
                Stage this Character, permitting full edits. Staged Character
                cannot be Checked-In and cannot receive XP.
              </div>
              {!canChangeCharacterStatus && (
                <div className="text-juno-orange-200 text-sm">
                  Please contact {branch} Staffs to Stage this Character.
                </div>
              )}
            </button>
          )}
          {character.status === ICharacterStatusEnum.Active && (
            <button
              type="button"
              className="p-2 text-left bg-transparent border border-juno-gray-700 mx-2 rounded opacity-75 hover:opacity-100 transition-all disabled:opacity-50 "
              disabled={!canRetireCharacter}
              onClick={() => {
                if ( showRetirementConfirmation ) {
                  retire({
                    characterId: character.id,
                  }).then( res => {
                    if ( res.data?.retireCharacter?.error ) {
                      setErrorMessage( res.data.retireCharacter.error );
                    }
                  });
                } else {
                  setShowRetirementConfirmation( true );
                }
              }}
            >
              <div>Retire</div>

              {retireResult.fetching ? (
                <div className="text-sm flex items-center gap-2">
                  Processing Retirement
                  <Loading size="small" />
                </div>
              ) : (
                <div className="text-sm opacity-75 grid gap-1">
                  <div>
                    Retire this character. A new Character will be created in
                    the process with the following XP:
                    <ul className="list-disc pl-4">
                      {retirementPaths &&
                        retirementPaths[alive ? 'alive' : 'dead'].map(
                          ( x, i ) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={i}>
                              {x.amount} XP, available{' '}
                              {x.earnedOn
                                ? `on ${format( parseISO( x.earnedOn ), 'yyyy MMMM d' )}`
                                : 'immediately'}
                            </li>
                          ),
                        )}
                    </ul>
                  </div>

                  <div>
                    Retirement is <u>irreversible</u>.
                  </div>
                  {showRetirementConfirmation && (
                    <div className="text-juno-orange-200 text-sm underline hover:brightness-150">
                      Click to confirm retirement.
                    </div>
                  )}
                </div>
              )}
              {!canRetireCharacter && (
                <div className="text-juno-orange-200 text-sm">
                  Please contact {branch} Staffs to Retire this Character.
                </div>
              )}
            </button>
          )}
          {character.status === ICharacterStatusEnum.Staged && (
            <button
              type="button"
              className="p-2 text-left bg-transparent border border-juno-gray-700 mx-2 rounded opacity-75 hover:opacity-100 transition-all disabled:opacity-50 "
              disabled={!canActivate}
              onClick={() => {
                setErrorMessage( null );
                update({
                  characterId: character.id,
                  status: ICharacterStatusEnum.Active,
                }).then( res => {
                  if ( res.data?.updateCharacterData?.error ) {
                    setErrorMessage( res.data.updateCharacterData.error );
                  }
                });
              }}
            >
              <div>Activate</div>
              <div className="text-sm opacity-75">
                Activating this Characters allows it to be Checked-In and
                receive XP. Edits are restricted to Staffs.
              </div>
              {!canActivate && (
                <div className="text-juno-orange-200 text-sm">
                  Active Characters limit reached.
                </div>
              )}
            </button>
          )}
          {canDestroyCharacter &&
            character.status === ICharacterStatusEnum.Staged && (
              <button
                type="button"
                className="p-2 text-left bg-transparent border border-juno-gray-700 mx-2 rounded opacity-75 hover:opacity-100 transition-all disabled:opacity-50 "
                disabled={character.buildEarned > 0}
                onClick={() => {
                  if ( showDestroyConfirmation ) {
                    destroy({
                      playerId: Number( playerId ),
                      characterId: character.id,
                    });
                    setIsDestroying( true );
                  } else {
                    setShowDestroyConfirmation( true );
                  }
                }}
              >
                <div>Destroy</div>
                <div className="text-sm opacity-75">
                  Permanently delete this Character.
                </div>
                {character.buildEarned > 0 && (
                  <div className="text-juno-orange-200 text-sm">
                    Character has non-zero XP.
                  </div>
                )}
                {showDestroyConfirmation && (
                  <div className="text-juno-orange-200 text-sm underline hover:brightness-150">
                    Click to confirm deletion.
                  </div>
                )}
              </button>
            )}
        </div>
      </div>
      {errorMessage && <ResponseBox type="error">{errorMessage}</ResponseBox>}
    </div>
  );
};

export default CharacterRow;
