import { useQuery } from 'urql';
import {
  IGetOrganizationSpecialDatesQuery,
  IGetOrganizationSpecialDatesQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { getOrganizationSpecialDates } from 'src/graphql/queries/organizations.graphql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationSpecialDates = () => {
  const { isRootUserValidated } = useAuth();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationSpecialDatesQuery,
    IGetOrganizationSpecialDatesQueryVariables
  >({
    query: getOrganizationSpecialDates,
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    specialDates: data?.organization.specialDates ?? [],
  };
};

export default useOrganizationSpecialDates;
