import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useOutsideClickRef } from 'rooks';

interface IProps {
  leftTitle?: string | ReactNode;
  title: string;
  isOpen: boolean;
  withCloseButton?: boolean;
  type?: 'default' | 'warning' | 'actionable';
  marginTop?: string;
  onClose?: () => void;
}

const Modal: FC<PropsWithChildren<IProps>> = ({
  leftTitle,
  title,
  isOpen,
  withCloseButton,
  type = 'default',
  marginTop = 'mt-[15vh]',
  children,
  onClose,
}) => {
  const [ ref ] = useOutsideClickRef(() => onClose?.());

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modal"
          initial={{ opacity: 0, zIndex: -50 }}
          animate={{ opacity: 1, zIndex: 50 }}
          exit={{ opacity: 0, zIndex: -50 }}
          className="fixed h-screen w-screen top-0 left-0 overflow-hidden backdrop-blur-sm backdrop-brightness-50 backdrop-saturate-0"
        >
          <div className={clsx( 'flex justify-center', marginTop )}>
            <div ref={ref} className="w-full md:w-3/4 max-w-[640px] gray-box">
              <div
                className={clsx( 'text-2xl p-2 text-center relative', {
                  'cyan-box': type === 'default',
                  'orange-box': type === 'warning',
                  'green-box': type === 'actionable',
                })}
              >
                {leftTitle && (
                  <div className="absolute left-0 ml-2">{leftTitle}</div>
                )}
                {title}
                {withCloseButton && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="absolute right-0 mt-1 mr-2 hover:text-shadow cursor-pointer"
                    onClick={onClose}
                  />
                )}
              </div>
              <div>{children}</div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
