import { FC } from 'react';
import useSideSelect from 'src/routes/Organization/Events/Event/Printouts/hooks/useSideSelect';
import Character, { TCharacter } from './Character';
import Player, { TPlayer } from './Player';
import Event, { TEvent } from './Event';
import { TShift, TTicket } from './TicketAndShifts';
import Backsheet from './Backsheet';

import './index.css';

interface IProps {
  character?: TCharacter;
  player?: TPlayer;
  event?: TEvent;
  shifts?: TShift[];
  ticket?: TTicket;
}

const DystopiaRisingPrintout: FC<IProps> = ({
  character,
  event,
  player,
  shifts,
  ticket,
}) => {
  const { printFront, printBack, printBoth } = useSideSelect();

  return (
    <>
      {printFront && (
        <div className="printout grid gap-2">
          <div className="flex justify-between text-center">
            <Player player={player} />
            <Event
              event={event}
              totalCapsForEvent={player?.totalCapsForEvent}
            />
          </div>
          <Character character={character} shifts={shifts} ticket={ticket} />
        </div>
      )}
      {printBack && (
        <Backsheet character={character} displayStats={printBoth} />
      )}
    </>
  );
};

export default DystopiaRisingPrintout;
