import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useItem from 'src/hooks/items/useItem';
import clsx from 'clsx';
import Loading from 'src/components/0100_loading';
import { useFormContext } from 'react-hook-form';
import ItemCrafting from './ItemCrafting';
import Procedure from './Procedure';
import usePrintability from '../../hooks/usePrintability';

const ItemPrintout: FC = () => {
  const { watch } = useFormContext();
  const { copies } = watch();
  const { eventId } = useParams();
  const { itemId } = useParams();
  const { item, stale } = useItem({ itemId: Number( itemId ) });
  const { isViewable, message } = usePrintability({ item });

  if ( !item && stale ) {
    return (
      <div className="printout non-printing">
        <Loading size="small" />
      </div>
    );
  }

  if ( !isViewable ) {
    return <div className="printout non-printing">{message}</div>;
  }

  if ( !item ) return null;

  return (
    <div>
      <div className="overflow-auto max-w-[calc(100vw-16px)]">
        <div
          className={clsx(
            'printout-container blueprint-font',
            message && 'no-print',
            !eventId && 'no-print',
          )}
        >
          {Array.from({ length: copies }).map(( _, i ) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="duplicates-control">
              {item.itemCraftings.length > 0 ? (
                item.itemCraftings.map( itemCrafting => (
                  <ItemCrafting
                    key={itemCrafting.id}
                    {...item}
                    itemCrafting={itemCrafting}
                    itemReproduction={item.itemReproductions[0]}
                  />
                ))
              ) : (
                <Procedure
                  {...item}
                  itemReproduction={item.itemReproductions[0]}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemPrintout;
