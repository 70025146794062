import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Input from 'src/components/0100_input';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import useItemEditPermission from 'src/components/0500_blueprints/dystopia_rising/hooks/useItemEditPermission';
import {
  destroyItemCraftingComponent,
  updateItemCraftingComponent,
} from 'src/graphql/mutations/items.graphql';
import {
  IDestroyItemCraftingComponentMutation,
  IDestroyItemCraftingComponentMutationVariables,
  IUpdateItemCraftingComponentMutation,
  IUpdateItemCraftingComponentMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import { useMutation } from 'urql';

export interface ICraftingComponent {
  id: number;
  amount: number;
  component: {
    id: number;
    name: string;
    kind: IItemKindEnum;
    grade: IItemGradeEnum;
  };
}

const ItemComponent: FC<ICraftingComponent> = ({ ...props }) => {
  const { canEdit } = useItemEditPermission();
  const { linkToDepository } = useAppNavigations();
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ isDeleting, setIsDeleting ] = useState( false );
  const [ error, setError ] = useState<string | null>( null );
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      amount: 1,
    },
  });
  const { amount } = watch();

  const [ , update ] = useMutation<
    IUpdateItemCraftingComponentMutation,
    IUpdateItemCraftingComponentMutationVariables
  >( updateItemCraftingComponent );
  const [ , destroy ] = useMutation<
    IDestroyItemCraftingComponentMutation,
    IDestroyItemCraftingComponentMutationVariables
  >( destroyItemCraftingComponent );

  const handleUpdate = useCallback(() => {
    if ( !isDirty ) return;

    setIsSuccessful( false );
    setError( null );
    update({ itemCraftingComponentId: props.id, amount: Number( amount ) }).then(
      res => {
        if ( res.data?.updateItemCraftingComponent?.craftingComponent ) {
          setIsSuccessful( true );
          setTimeout(() => setIsSuccessful( false ), 3000 );
        }

        if ( res.data?.updateItemCraftingComponent?.error ) {
          setError( res.data.updateItemCraftingComponent.error );
        }
      },
    );
  }, [ amount, isDirty, props.id, update ]);

  const handleDestroy = useCallback(() => {
    destroy({ itemCraftingComponentId: props.id });
    setIsDeleting( true );
  }, [ destroy, props.id ]);

  useEffect(() => {
    reset({
      amount: Number( props.amount ),
    });
  }, [ props.id, props.amount, reset ]);

  if ( isDeleting ) return null;

  return (
    <div
      className={clsx(
        'flex justify-between items-center gap-4 w-full p-2 border-l-4 border-b border-juno-gray-700 transition-all duration-300 hover:border-l-juno-gray-200 hover:text-shadow',
        isSuccessful && 'border-l-juno-cyan-200 bg-juno-cyan-200',
      )}
    >
      <div className="flex items-center gap-4">
        <div className="w-20">
          <Input
            fullWidth
            disabled={!canEdit}
            type="number"
            className="text-right"
            {...register( 'amount' )}
            onBlur={handleUpdate}
          />
        </div>
        <div>
          <Link
            className="underline"
            to={linkToDepository({ itemId: props.component.id })}
          >
            {props.component.name}
          </Link>
          <div className="text-sm opacity-75">
            {startCase( props.component.kind )}
          </div>
          {error && <div className="text-juno-orange-400">{error}</div>}
        </div>
      </div>
      {canEdit && (
        <div>
          <ConfirmDropdown onConfirm={handleDestroy} />
        </div>
      )}
    </div>
  );
};

export default ItemComponent;
