import { FC, useMemo } from 'react';
import lineageData from 'src/components/0200_lineage_dropdown/data';
import { TCharacter } from '../Character';

interface IProps {
  character?: TCharacter;
  displayStats?: boolean;
}

const Backsheet: FC<IProps> = ({ character, displayStats }) => {
  const lineage = lineageData.find( x => x.id === character?.lineageId );
  const mindPenalty = useMemo(() => {
    switch ( character?.fractures?.length ?? 0 ) {
      case 0:
        return null;
      case 1:
        return 5;
      case 2:
        return 15;
      case 3:
        return 30;
      default:
        return 50;
    }
  }, [ character?.fractures.length ]);

  return (
    <div className="printout">
      <div className="flex justify-between items-center">
        <div>Use this sheet to record activities.</div>
        {displayStats && (
          <div className="flex">
            <b>Mind:</b>
            <span className="mx-1">
              {( lineage?.mind ?? 0 ) +
                ( character?.mind ?? 0 ) -
                ( mindPenalty ?? 0 )}
            </span>
            <span className="mr-1">|</span>
            <b>Resolve:</b>
            <span className="ml-1">{3 + ( character?.resolve ?? 0 )}</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        {[ ...Array( 2 ) ].map(( _, i ) => (
          // eslint-disable-next-line react/no-array-index-key
          <table key={i} className="w-full">
            <thead>
              <tr>
                <th className="border border-juno-gray-700">Re</th>
                <th className="border border-juno-gray-700">Actions</th>
                <th className="border border-juno-gray-700">Mi</th>
                <th className="border border-juno-gray-700">Staff Sig</th>
              </tr>
            </thead>
            <tbody className="grid-backsheet">
              {[ ...Array( 34 ) ].map(( _, j ) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={j}>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};

export default Backsheet;
