import clsx from 'clsx';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from 'src/components/0100_button';
import Loading from 'src/components/0100_loading';
import Textarea from 'src/components/0100_textarea';
import AugmentedInput from 'src/components/0200_augmented_input';
import {
  createNewPlayer,
  updatePlayerData,
} from 'src/graphql/mutations/players.graphql';
import {
  ICreateNewPlayerMutation,
  ICreateNewPlayerMutationVariables,
  IUpdatePlayerDataMutation,
  IUpdatePlayerDataMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';
import { useMutation } from 'urql';
import ResponseBox from 'src/components/0100_response_box';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { IAssistantFlagEnum, IOrganizationRoleEnum } from 'src/graphql/types';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import BaseSection from './BaseSection';
import SecuritySection from './SecuritySection';
import RoleSection from './RoleSection';

const defaultValues = {
  id: 0,
  firstName: '',
  lastName: '',
  preferredName: '',
  emailAddress: '',
  branchId: 0,
  role: IOrganizationRoleEnum.Regular,
  assistantFlags: [] as IAssistantFlagEnum[],
  notes: '',
};

const Bio: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const { linkToPlayer } = useAppNavigations();
  const { buttonState } = useButtonStates();
  const isNewPlayer = playerId === 'new';

  const { data, fetching, stale } = usePlayerWithCharactersInOrganization({
    playerId: Number( playerId ),
  });

  const { isPermitted: canUpdatePlayerData } = usePermission({
    action: 'update_player_data',
    playerId: Number( playerId ),
    pause: isNewPlayer,
  });
  const { isPermitted: canUpdatePlayerDataSensitive } = usePermission({
    action: 'update_player_data_sensitive',
    playerId: Number( playerId ),
    pause: isNewPlayer,
  });
  const { isPermitted: canUpdatePlayerOrganizationData } = usePermission({
    action: 'update_player_organization_data',
    pause: isNewPlayer,
  });

  const [ updatedFields, setUpdatedFields ] = useState<{
    [key: string]: boolean;
  }>({});

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const {
    getValues,
    register,
    resetField,
    setError,
    setFocus,
    setValue,
    reset,
    watch,
    formState: { dirtyFields, errors, isValid },
  } = methods;

  const [ updateResult, update ] = useMutation<
    IUpdatePlayerDataMutation,
    IUpdatePlayerDataMutationVariables
  >( updatePlayerData );

  const [ createResult, create ] = useMutation<
    ICreateNewPlayerMutation,
    ICreateNewPlayerMutationVariables
  >( createNewPlayer );

  const handlePartialSubmit = useCallback(() => {
    if ( isNewPlayer ) return;

    const dirtyField = Object.keys( dirtyFields )[0] as keyof typeof dirtyFields;
    setUpdatedFields( prev => ({ ...prev, [dirtyField]: false }));
    update({
      playerId: Number( playerId ),
      [dirtyField]: getValues( dirtyField ),
    }).then( res => {
      if ( res.data?.updatePlayerData?.error ) {
        resetField( dirtyField );
        setError( dirtyField, { message: res.data.updatePlayerData.error });
        setFocus( dirtyField );
      } else if ( dirtyField ) {
        setUpdatedFields( prev => ({
          ...prev,
          [dirtyField]: true,
        }));
      }
    });
  }, [
    dirtyFields,
    getValues,
    isNewPlayer,
    playerId,
    resetField,
    setError,
    setFocus,
    update,
  ]);

  const handleCreate = useCallback(() => {
    if ( !isNewPlayer ) return;
    const { firstName, lastName, preferredName, emailAddress } = getValues();

    create({
      firstName,
      lastName,
      preferredName,
      emailAddress,
      branchId: Number( getValues( 'branchId' )),
    }).then( res => {
      if ( res.data?.createNewPlayer?.user ) {
        resetField( 'firstName' );
        resetField( 'lastName' );
        resetField( 'emailAddress' );
        resetField( 'preferredName' );
      }
    });
  }, [ isNewPlayer, getValues, create, resetField ]);

  const memoizedPlayerData = useMemo(() => {
    if ( !data?.user ) {
      return defaultValues;
    }

    return {
      id: data.user.id,
      firstName: data.user.firstName ?? '',
      lastName: data.user.lastName ?? '',
      preferredName: data.user.preferredName ?? '',
      emailAddress: data.user.emailAddress ?? '',
      notes: data.user.notes ?? '',
      branchId: data.user.userOrganization?.branch.id ?? 0,
      role: data.user.userOrganization?.role ?? IOrganizationRoleEnum.Regular,
      assistantFlags: data.user.userOrganization?.assistantFlags ?? [],
    };
  }, [ data?.user ]);

  useEffect(() => {
    reset( isNewPlayer ? defaultValues : memoizedPlayerData );
  }, [ isNewPlayer, memoizedPlayerData, reset ]);

  useEffect(() => {
    setUpdatedFields({});
  }, [ isNewPlayer, playerId ]);

  if ( fetching ) return <Loading className="p-2" />;

  return (
    <div className={clsx( stale && 'blur-xs' )}>
      <div>
        <FormProvider {...methods}>
          <BaseSection
            canUpdatePlayerData={!!canUpdatePlayerData}
            canUpdatePlayerDataSensitive={!!canUpdatePlayerDataSensitive}
            isNewPlayer={isNewPlayer}
            isUpdating={updateResult.fetching}
            isUpdated={updatedFields}
            isDirty={{
              firstName: !!dirtyFields.firstName,
              lastName: !!dirtyFields.lastName,
              preferredName: !!dirtyFields.preferredName,
              emailAddress: !!dirtyFields.emailAddress,
              branchId: !!dirtyFields.branchId,
            }}
            errors={{
              firstName: errors.firstName,
              lastName: errors.lastName,
              preferredName: errors.preferredName,
              emailAddress: errors.emailAddress,
              branchId: errors.branchId,
            }}
            onUpdate={handlePartialSubmit}
          />

          {!isNewPlayer && (
            <>
              <SecuritySection />
              {( canUpdatePlayerDataSensitive ||
                [ 'assistant', 'employee' ].includes(
                  String( data?.user?.userOrganization?.role ),
                )) && (
                <RoleSection
                  canUpdateRole={!!canUpdatePlayerOrganizationData}
                  isAssistant={
                    data?.user?.userOrganization?.role === 'assistant'
                  }
                  isEmployee={data?.user?.userOrganization?.role === 'employee'}
                  isUpdating={updateResult.fetching}
                  isUpdated={updatedFields}
                  isDirty={{
                    role: !!dirtyFields.role,
                    assistantFlags: !!dirtyFields.assistantFlags,
                  }}
                  onUpdate={handlePartialSubmit}
                />
              )}
            </>
          )}

          <AugmentedInput
            key="notes"
            title="Player Notes"
            subtitle={
              <div className="opacity-50 grid items-center">
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faEye} className="fa-fw" />
                  Visible to Player
                </div>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faTimes} className="fa-fw" />
                  Does not print on Character Sheet
                </div>
              </div>
            }
            isBusy={updateResult.fetching && dirtyFields.notes}
            isUpdated={updatedFields.notes && !dirtyFields.notes}
          >
            <Textarea
              defaultValue={watch( 'notes' ) || ''}
              disabled={!isNewPlayer && !canUpdatePlayerDataSensitive}
              {...register( 'notes' )}
              onBlur={x => {
                setValue( 'notes', x.target.value, { shouldDirty: true });
                handlePartialSubmit();
              }}
            />
          </AugmentedInput>
        </FormProvider>
      </div>

      {isNewPlayer && (
        <>
          {createResult.data?.createNewPlayer?.error && (
            <ResponseBox type="error">
              {createResult.data.createNewPlayer.error}
            </ResponseBox>
          )}
          {createResult.data?.createNewPlayer?.user && (
            <ResponseBox type="success">
              <Link
                to={linkToPlayer({
                  playerId: createResult.data.createNewPlayer.user.id,
                })}
                className="underline"
              >{`Player #${createResult.data.createNewPlayer.user.id}`}</Link>{' '}
              successfully created.
            </ResponseBox>
          )}
          <div className="flex justify-end py-2 w-full">
            <Button
              defaultLabel="Create New Player"
              state={buttonState({
                isHighlight: true,
                isDirty: !!dirtyFields,
                isFetching: createResult.fetching,
                isValid,
              })}
              stateLabel={{
                loading: 'Creating New Player...',
              }}
              onClick={handleCreate}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Bio;
