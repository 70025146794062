import { FC } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import useEvent from 'src/hooks/events/useEvent';
import useEmbossPrintTime from '../hooks/useEmbossPrintTime';

interface IProps {
  version: string;
}

const Security: FC<IProps> = ({ version }) => {
  const { rootUser } = useRootUser();
  const { printTime, crc } = useEmbossPrintTime();
  const { event } = useEvent();

  if ( !rootUser ) return null;
  if ( !event ) return null;

  return (
    <div className="security text-xs font-extralight rotate-180 absolute h-0 w-0 right-[0.27in] bottom-[0.5in]">
      <div className="h-[10in] flex items-center justify-between gap-2">
        <div>
          {[
            `Version ${version}`,
            crc,
            `Printed by #${rootUser.id}`,
            rootUser.fullName,
            event.id,
            event.name.slice( 0, 24 ),
            printTime,
          ].join( ' - ' )}
        </div>

        <div className="barcode">{crc}</div>
      </div>
    </div>
  );
};

export default Security;
