import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';

const CreateNewEventSidebar = () => {
  const { organizationSlug } = useParams();

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faPlus} className="pr-2 fa-fw" />
          Create New Event
        </div>
      }
      to={`/${organizationSlug}/events/new`}
    />
  );
};

export default CreateNewEventSidebar;
