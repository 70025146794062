import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  FC,
  InputHTMLAttributes,
  LegacyRef,
  ReactNode,
  forwardRef,
} from 'react';
import Loading from '../0100_loading';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  isLocked?: boolean;
  isFetching?: boolean;
  contentWhenLocked?: string | ReactNode;
  showLockIcon?: boolean;
  ref?: LegacyRef<HTMLInputElement>;
  fullWidth?: boolean;
  width?: string;
  onEnter?: () => void;
}

const Input: FC<IProps> = forwardRef<HTMLInputElement, IProps>(
  (
    {
      type = 'text',
      contentWhenLocked,
      isLocked = false,
      isFetching = false,
      showLockIcon = true,
      placeholder,
      className,
      fullWidth = false,
      width,
      onEnter,
      ...props
    },
    ref,
  ) => {
    if ( contentWhenLocked !== undefined && isLocked ) {
      return <div>{contentWhenLocked}</div>;
    }

    return (
      <div className={clsx( 'flex items-center', fullWidth && 'w-full' )}>
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          className={clsx(
            'rounded align-top py-1 px-2 dark-box border border-juno-gray-700 w-48 disabled:opacity-50',
            fullWidth && 'w-full',
            width,
            className,
          )}
          disabled={isLocked}
          onKeyDown={x => x.key === 'Enter' && onEnter?.()}
          {...props}
        />
        {isLocked && showLockIcon && (
          <FontAwesomeIcon icon={faLock} className="-ml-6 opacity-50" />
        )}
        {isFetching && (
          <div className="relative -ml-7 mt-1">
            <Loading size="small" />
          </div>
        )}
      </div>
    );
  },
);

export default Input;
