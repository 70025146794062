import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import useItems from 'src/hooks/items/useItems';
import { IItemKindEnum } from 'src/graphql/types';
import { startCase } from 'lodash';
import Button from 'src/components/0100_button';
import usePermission from 'src/hooks/permissions/usePermissions';
import useAuth from 'src/hooks/auths/useAuth';
import NewItem from './NewItem';
import ItemList from './ItemList';
import ItemView from '../ItemView';

const filters: IItemKindEnum[] = [
  IItemKindEnum.Blueprint,
  IItemKindEnum.Taxonomy,
  IItemKindEnum.CraftingOutput,
];

export type TLoupeViewProps = {
  components?: {
    withTitle: boolean;
    withItemCreation: boolean;
  };
};

interface IProps extends TLoupeViewProps {}

const LoupeView: FC<IProps> = ({ components = {}}) => {
  const { isRootUserValidated } = useAuth();
  const { itemId } = useParams();
  const methods = useForm({ defaultValues: { copies: 1 }});
  const [ activeFilter, setActiveFilter ] = useState<IItemKindEnum>(
    IItemKindEnum.Blueprint,
  );
  const { isPermitted: canCreateItem } = usePermission({
    action: 'create_item',
  });

  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const { fetching, items, refetch } = useItems({
    keyword: query,
    kind: activeFilter,
  });
  const matchingItem = useMemo(
    () => items.find( x => x.id === Number( itemId )),
    [ itemId, items ],
  );
  const itemExists = !!matchingItem;

  useEffect(() => {
    if ( !fetching && itemExists ) {
      setTimeout(() => {
        const element = document.getElementById( `item-${itemId}` );
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 250 );
    }
  }, [ fetching, itemExists, itemId ]);

  useEffect(() => {
    if ( matchingItem?.kind ) {
      if ( filters.includes( matchingItem.kind )) {
        setActiveFilter( matchingItem.kind );
      } else {
        setActiveFilter( IItemKindEnum.CraftingOutput );
      }
    }
  }, [ matchingItem?.kind ]);

  if ( !isRootUserValidated ) return null;

  return (
    <FormProvider {...methods}>
      <div className="grid place-self-center w-full 2xl:w-auto">
        {components.withTitle && <Title title="Blueprints" />}
        <div className="grid grid-cols-1 lg:flex gap-4 items-start lg:px-4">
          <div className="grid lg:min-w-[480px] lg:max-w-[640px]">
            {components.withItemCreation && canCreateItem && (
              <div className="z-30">
                <NewItem onCreate={refetch} />
              </div>
            )}

            <div className="w-full sticky top-0 gray-box-angled z-20 mb-2">
              {components.withItemCreation && canCreateItem && (
                <div className="flex flex-wrap items-center gap-2 pb-2">
                  {filters.map( kind => (
                    <Button
                      key={kind}
                      defaultLabel={
                        kind === IItemKindEnum.CraftingOutput
                          ? 'Others'
                          : startCase( kind )
                      }
                      state={
                        activeFilter === kind ? 'enabled-highlight' : 'enabled'
                      }
                      onClick={() => setActiveFilter( kind )}
                    />
                  ))}
                </div>
              )}
              <div className="flex justify-between items-center gap-2">
                <Input
                  fullWidth
                  placeholder="Search Items..."
                  isFetching={fetching}
                  {...register( 'query' )}
                />
              </div>
            </div>

            {fetching && items.length === 0 && (
              <div>
                <Loading size="small" />
              </div>
            )}
            {!fetching && items.length === 0 && (
              <div className="opacity-50">Nothing found</div>
            )}
            <ItemList
              items={items}
              printoutOnly={!components.withItemCreation}
            />
          </div>
          <div className="hidden lg:block sticky top-0">
            {itemId ? (
              <ItemView printoutOnly={!components.withItemCreation} />
            ) : (
              <div className="lg:w-[7.5in]" />
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default LoupeView;
