import { format, isBefore, parseISO } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useOrganizationRebirthedCharacters from 'src/hooks/organizations/useOrganizationRebirthedCharacters';

const RebirthedCharacters: FC = () => {
  const { rebirthedCharacters } = useOrganizationRebirthedCharacters();
  const { linkToPlayer, linkToCharacter } = useAppNavigations();

  return (
    <table className="basic-table table-fixed">
      <thead>
        <tr>
          <th>ID</th>
          <th>Player Name</th>
          <th>Rebirthed Character</th>
          <th>Retired Character</th>
        </tr>
      </thead>
      <tbody>
        {rebirthedCharacters
          .sort(( a, b ) =>
            isBefore( parseISO( a.createdAt ), parseISO( b.createdAt )) ? 1 : -1,
          )
          .map( x => (
            <tr key={x.id}>
              <td className="text-right">
                <Link
                  to={linkToPlayer({ playerId: x.user.id })}
                  className="underline"
                >
                  {x.user.id}
                </Link>
              </td>
              <td>
                <div>{x.user.fullName}</div>
                <div className="text-sm opacity-50">
                  {x.user.userOrganization?.branch.name}
                </div>
              </td>
              <td>
                <Link
                  to={linkToCharacter({
                    playerId: x.user.id,
                    characterId: x.id,
                  })}
                  className="underline"
                >
                  {x.name}
                </Link>
                <div className="text-sm opacity-50">
                  {x.legacyHomeGameCount} Games
                </div>
              </td>
              {x.retiredCharacter ? (
                <td>
                  <Link
                    to={linkToCharacter({
                      playerId: x.user.id,
                      characterId: x.retiredCharacter.id,
                    })}
                    className="underline"
                  >
                    {x.retiredCharacter?.name}
                  </Link>
                  <div className="text-sm opacity-50">
                    {format( parseISO( x.createdAt ), 'yyyy-MM-dd' )}
                  </div>
                </td>
              ) : (
                <td />
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RebirthedCharacters;
