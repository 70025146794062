import { FC } from 'react';
import Title from 'src/components/0100_title';
import Toggle from 'react-toggle';
import ResponseBox from 'src/components/0100_response_box';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import useSideSelect from '../../hooks/useSideSelect';

const SideSelect: FC = () => {
  const { handleChange, printBack, printFront } = useSideSelect();

  return (
    <div>
      <Title variant="heading">Sides</Title>
      <div className="grid gap-2">
        <div className="flex items-center gap-2">
          <Toggle
            checked={printFront}
            onChange={x =>
              handleChange({ back: printBack, front: x.target.checked })
            }
          />
          <div>Front</div>
        </div>
        <div className="flex items-center gap-2">
          <Toggle
            checked={printBack}
            onChange={x =>
              handleChange({ back: x.target.checked, front: printFront })
            }
          />
          <div>Back</div>
        </div>
        <ResponseBox type="neutral" withIcon={faTriangleExclamation}>
          <div className="text-sm">
            For stats to appear on the backsheet, you must print both sides
            together
          </div>
        </ResponseBox>
      </div>
    </div>
  );
};

export default SideSelect;
