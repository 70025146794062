import { FC, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faChevronDown,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useWindowSize } from 'rooks';
import { useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Button from 'src/components/0100_button';
import TicketSelect from './TicketSelect';
import Boolean from './Boolean';
import SideSelect from './SideSelect';

const Filters: FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { innerWidth } = useWindowSize();
  const [ isOpen, setIsOpen ] = useState( false );
  const isBlankSheet = searchParams.get( 'blank_sheet' ) === 'true';
  const toggleBlankSheet = useCallback(() => {
    if ( isBlankSheet ) {
      searchParams.delete( 'blank_sheet' );
    } else {
      searchParams.set( 'blank_sheet', 'true' );
    }

    setSearchParams( searchParams );
  }, [ isBlankSheet, searchParams, setSearchParams ]);

  useEffect(() => {
    if (( innerWidth ?? 0 ) > 768 ) {
      setIsOpen( true );
    }
  }, [ innerWidth ]);

  return (
    <div className="sticky top-0">
      <button
        type="button"
        className="w-full flex md:hidden justify-between items-center"
        onClick={() => setIsOpen( x => !x )}
      >
        <div>Filters</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'transition-all duration-300',
            isOpen && '-scale-y-100',
          )}
        />
      </button>

      <div
        className={clsx(
          'grid transition-all duration-300',
          isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className="pt-2 overflow-hidden">
          <div className="cursor-pointer pl-1">
            {isBlankSheet ? (
              <div className="grid gap-4">
                <button
                  type="button"
                  className="flex items-center gap-2.5"
                  onClick={toggleBlankSheet}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Return to Printouts
                </button>
                <div>
                  <Button
                    defaultLabel="Print"
                    className="w-32"
                    onClick={() => window.print()}
                  />
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="flex items-center gap-2.5 underline"
                onClick={toggleBlankSheet}
              >
                <FontAwesomeIcon icon={faLightbulb} />
                Need to print a blank sheet?
              </button>
            )}
          </div>
          <AnimatePresence>
            {!isBlankSheet && (
              <motion.div
                initial={{ maxHeight: 0, opacity: 0 }}
                animate={{ maxHeight: '1000vh', opacity: 1 }}
                exit={{ maxHeight: 0, opacity: 0 }}
              >
                <SideSelect />
                <Boolean
                  filterKey="print_requested"
                  title="Print Request"
                  trueLabel="Requested"
                  falseLabel="Declined"
                />
                <Boolean
                  filterKey="paid"
                  title="Payment"
                  trueLabel="Paid"
                  falseLabel="Not Paid"
                />
                <Boolean
                  filterKey="home_game"
                  title="Residency"
                  trueLabel="Locals"
                  falseLabel="Travelers"
                />
                <TicketSelect />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Filters;
