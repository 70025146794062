import { FC, ReactNode, forwardRef } from 'react';
import ReactDatePicker, { DatePickerProps } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Loading from '../0100_loading';
import LeanInput from './LeanInput';

interface IProps extends Omit<DatePickerProps, 'onChange'> {
  complex?: boolean;
  date?: Date;
  dateFormat?: string;
  isUpdating?: boolean;
  isUpdated?: boolean;
  placeholder?: string;
  error?: string;
  fillWidth?: boolean;
  onUpdate: ( date: Date | null ) => void;
}

const DatePicker: FC<IProps> = forwardRef(
  (
    {
      complex = false,
      date,
      dateFormat = 'yyyy-MM-dd',
      isUpdating = false,
      isUpdated = false,
      placeholder,
      error,
      fillWidth,
      onUpdate,
      ...props
    },
    ref,
  ) => (
    <div className="grid grid-cols-1 gap-4">
      <div className="flex items-center gap-2">
        <div className={clsx( complex ? 'w-full' : 'w-24' )}>
          <ReactDatePicker
            {...props}
            selected={date}
            dateFormat={dateFormat}
            placeholderText={placeholder}
            className={clsx(
              'rounded py-1 px-2 dark-box border border-juno-gray-700 disabled:opacity-50',
              fillWidth && 'fill',
            )}
            // @ts-ignore
            customInput={complex ? undefined : (( <LeanInput /> ) as ReactNode )}
            onChange={onUpdate}
          />
        </div>
        {isUpdating && <Loading size="small" />}
        {isUpdated && <FontAwesomeIcon icon={faCheck} />}
      </div>
      {!!error && <div className="text-juno-orange-200">{error}</div>}
    </div>
  ),
);

export default DatePicker;
