import { FC } from 'react';

import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import clsx from 'clsx';
import Tab from '../0200_tab';
import PlayerBadges, { TUserOrganization } from '../0200_player_badges';

interface IProps {
  characterOnly?: boolean;
  playerId?: number;
  playerName?: string;
  playerFirstName?: string | null;
  playerLastName?: string;
  playerHasPreferredName?: boolean;
  characterId?: number;
  characterName?: string;
  userOrganization?: TUserOrganization;
  onClick?: ({
    playerId,
    characterId,
  }: {
    playerId?: number;
    characterId?: number;
  }) => void;
}

const SearchResult: FC<IProps> = ({
  characterOnly,
  playerId,
  playerName,
  playerFirstName,
  playerLastName,
  playerHasPreferredName,
  characterId,
  characterName,
  userOrganization,
  onClick,
}) => {
  const { linkToPlayer, linkToCharacter, playerSection, characterSection } =
    useAppNavigations();

  return (
    <Tab
      isCompact
      label={
        <div className="flex">
          <div className="w-12 pr-2 text-right">
            {!characterOnly && <div>{playerId}</div>}
            {!characterOnly && characterName && <div>&raquo;</div>}
          </div>
          <div className="w-48 text-left">
            {!characterOnly && (
              <div className={clsx( characterName && 'text-juno-gray-700' )}>
                <div>
                  {playerName}
                  <PlayerBadges userOrganization={userOrganization} />
                </div>
                {playerHasPreferredName && (
                  <div className="opacity-50">
                    a.k.a. {[ playerFirstName, playerLastName ].join( ' ' )}
                  </div>
                )}
              </div>
            )}
            {characterName && <div>{characterName}</div>}
          </div>
        </div>
      }
      className="py-1"
      highlightMode="vertical"
      to={
        onClick
          ? undefined
          : characterId
            ? linkToCharacter({
                playerId,
                characterId,
                section: characterSection,
              })
            : linkToPlayer({ playerId, section: playerSection })
      }
      onClick={() => onClick?.({ playerId, characterId })}
    />
  );
};

export default SearchResult;
