import { FC, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import useRootUser from 'src/hooks/players/useRootUser';
import usePreferences from 'src/hooks/preferences/usePreferences';
import usePermission from 'src/hooks/permissions/usePermissions';
import PlayerSearch from 'src/components/0400_player_search';
import PlayerSidebar from './PlayerSidebar';
import CreateNewPlayerSidebar from './CreateNewPlayerSidebar';
import CreateNewEventSidebar from './CreateNewEventSidebar';
import CurrentEventSidebar from './CurrentEventSidebar';
import OrganizationSidebar from './OrganizationSidebar';
import EventCalendarSidebar from './EventCalendarSidebar';
import CheckoutSidebar from './CheckoutSidebar';
import BranchesSidebar from './BranchesSidebar';
import BlueprintSidebar from './BlueprintSidebar';

const Sidebar: FC = () => {
  const { inspectedPlayerId, setInspectedPlayerId } = usePreferences();
  const { rootUserId } = useRootUser();
  const { playerId } = useParams();
  const { isPermitted: canSearchPlayers } = usePermission({
    action: 'search_player',
  });
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });
  const { isPermitted: canCreateNewEvent } = usePermission({
    action: 'create_event_ui',
  });
  const { isPermitted: canUseNetworkTools } = usePermission({
    action: 'network_tools',
  });

  useEffect(() => {
    if ( playerId && Number( playerId ) !== rootUserId ) {
      setInspectedPlayerId( Number( playerId ));
    }
  }, [ playerId, rootUserId, setInspectedPlayerId ]);

  return (
    <Routes>
      {!rootUserId && <Route path="/" element={<EventCalendarSidebar />} />}
      <Route path="/:organizationSlug">
        <Route
          index
          path="*"
          element={
            <>
              {canUseNetworkTools && <OrganizationSidebar />}
              <BranchesSidebar />
              <PlayerSidebar key={rootUserId} playerId={rootUserId} />

              {canSearchPlayers && (
                <div className="p-2 border-l-4 border-transparent hover:border-juno-gray-200 focus-within:border-juno-gray-200">
                  <PlayerSearch
                    // withCharacters
                    placeholder="Search Players..."
                  />
                </div>
              )}
              {inspectedPlayerId > 0 &&
                Number( inspectedPlayerId ) !== rootUserId && (
                  <PlayerSidebar
                    isOtherPlayer
                    key={inspectedPlayerId}
                    dismissable={Number( playerId ) !== inspectedPlayerId}
                    playerId={Number( inspectedPlayerId )}
                  />
                )}

              {canCreateNewPlayer && <CreateNewPlayerSidebar />}
              <BlueprintSidebar />
              <EventCalendarSidebar />
              {canCreateNewEvent && <CreateNewEventSidebar />}
              <CurrentEventSidebar />
              <CheckoutSidebar />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default Sidebar;
