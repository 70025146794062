import { useLocation, useParams } from 'react-router-dom';
import { IFellowshipKindEnum } from 'src/graphql/types';
import useRootUser from '../players/useRootUser';

const useAppNavigations = () => {
  const location = useLocation();
  const {
    organizationSlug,
    playerId: playerIdInURL,
    characterId: characterIdInURL,
    eventId: eventIdInURL,
  } = useParams();
  const { rootUser } = useRootUser();

  const publicLink = ({
    organizationSlug,
    section,
  }: {
    organizationSlug: string;
    section: string;
  }) => `/${organizationSlug}/${section}`;
  const linkToOrganization = ({
    section = '',
    subsection,
  }: {
    section: string;
    subsection?: string;
  }) =>
    subsection
      ? `/${organizationSlug}/${section}/${subsection}`
      : `/${organizationSlug}/${section}`;
  const linkToPlayer = ({
    playerId = Number( playerIdInURL ),
    section = '',
  }: {
    playerId?: number;
    section?: string;
  }) => `/${organizationSlug}/players/${playerId}/${section}`;
  const linkToCaps = ({ branchId }: { branchId?: number }) =>
    `/${organizationSlug}/reports/caps/${branchId}`;
  const linkToCorrectiveActions = ({ branchId }: { branchId?: number }) =>
    `/${organizationSlug}/reports/corrective_actions/${branchId}`;
  const linkToCharacters = ({
    playerId = Number( playerIdInURL ),
  }: {
    playerId?: number;
  }) => `/${organizationSlug}/players/${playerId}/characters`;
  const linkToCharacter = ({
    playerId = Number( playerIdInURL ),
    characterId = Number( characterIdInURL ),
    section = '',
  }: {
    playerId?: number;
    characterId?: number;
    section?: string;
  }) =>
    `/${organizationSlug}/players/${playerId}/characters/${characterId}/${section}`;
  const linkToEvent = ({
    eventId = Number( eventIdInURL ),
    section = '',
    searchParams = [],
  }: {
    eventId?: number;
    section?: string;
    searchParams?: string[];
  }) =>
    `/${organizationSlug}/events/${eventId}/${section}${searchParams && `?${searchParams.join( '&' )}`}`;
  const linkToEventCharacterCheckout = ({
    playerId,
    characterId,
    section,
  }: {
    playerId: number;
    characterId: number;
    section?: string;
  }) =>
    `/${organizationSlug}/events/${eventIdInURL}/checkout?player_id=${playerId}&character_id=${characterId}${section ? `&section=${section}` : ''}`;
  const linkToEventPlayerCheckout = ({
    playerId,
    section,
  }: {
    playerId: number;
    section?: string;
  }) =>
    `/${organizationSlug}/events/${eventIdInURL}/checkout?player_id=${playerId}${section ? `&section=${section}` : ''}`;
  const linkToBranch = ({
    branchId,
    section,
  }: {
    branchId?: number;
    section?: string;
  }) =>
    branchId
      ? `/${organizationSlug}/branches/${branchId}/${section ?? ''}`
      : `/${organizationSlug}/branches`;
  const linkToEvents = organizationSlug
    ? `/${organizationSlug}/events`
    : 'events';
  const linkToDepository = ({
    itemId,
    searchParams = '',
    isTabular = false,
  }: {
    itemId?: number;
    searchParams?: string;
    isTabular?: boolean;
  }) => {
    const baseUrl = eventIdInURL
      ? `/${organizationSlug}/events/${eventIdInURL}/blueprints`
      : `/${organizationSlug}/blueprints`;

    if ( isTabular ) return `${baseUrl}/tabular`;

    return itemId ? `${baseUrl}/${itemId}${searchParams}` : baseUrl;
  };
  const linkToFellowship = ({
    fellowshipId,
    type,
  }: {
    fellowshipId?: number;
    type: IFellowshipKindEnum;
  }) => {
    const subpath = () => {
      switch ( type ) {
        case IFellowshipKindEnum.Disease:
          return 'mechanics/diseases';
        case IFellowshipKindEnum.Society:
          return 'mechanics/societies';
        default:
          return '';
      }
    };
    return fellowshipId
      ? `/${organizationSlug}/${subpath()}/${fellowshipId}`
      : `/${organizationSlug}/${subpath()}`;
  };

  const pathnames = location.pathname.split( '/' );

  const isBranchManagementPath =
    pathnames.at( 2 ) === 'branches' && Number( pathnames.at( 3 )) > 0;
  const isEventPath =
    pathnames.at( 2 )?.startsWith( 'events' ) ||
    pathnames.at( 1 )?.startsWith( 'events' );
  const isEventsPath = isEventPath && !eventIdInURL;
  const isOrganizationPath =
    pathnames.at( 1 ) === organizationSlug &&
    ![ 'players', 'events', 'branches' ].includes( String( pathnames.at( 2 )));
  const isOrganizationCharacterDemoPath =
    pathnames.at( 1 ) === organizationSlug && pathnames.at( 2 ) === 'characters';

  const organizationSection = [ 'players', 'events' ].includes(
    String( pathnames.at( 2 )),
  )
    ? null
    : pathnames.at( 2 );

  const playerSection =
    pathnames.at( 2 ) === 'players' && pathnames.at( 4 ) !== 'characters'
      ? pathnames.at( 4 )
      : '';
  const branchSection = isBranchManagementPath ? pathnames.at( 4 ) : null;
  const characterSection =
    pathnames.at( 4 ) === 'characters' ? pathnames.at( 6 ) : '';
  const charactersManagementSection = ({ playerId }: { playerId: number }) =>
    pathnames.at( 4 ) === 'characters' &&
    pathnames.length === 5 &&
    playerId === Number( playerIdInURL );
  const eventSection = pathnames.at( 2 ) === 'events' ? pathnames.at( 4 ) : '';
  const depositorySection = pathnames.at( 3 ) !== 'tabular' ? 'loupe' : 'tabular';
  const mechanicsSection =
    pathnames.at( 2 ) === 'mechanics'
      ? ( pathnames.at( 3 ) ?? 'diseases' )
      : undefined;
  const reportsSection =
    pathnames.at( 2 ) === 'reports'
      ? ( pathnames.at( 3 ) ?? 'memberships' )
      : undefined;

  const currentOrganization = rootUser.organizations.find(
    x => x.slug === organizationSlug,
  ) || { id: 0 };
  const { id: organizationId } = currentOrganization;

  return {
    publicLink,
    linkToOrganization,
    linkToPlayer,
    linkToBranch,
    linkToCaps,
    linkToCorrectiveActions,
    linkToCharacter,
    linkToCharacters,
    linkToDepository,
    linkToEvent,
    linkToEventCharacterCheckout,
    linkToEventPlayerCheckout,
    linkToEvents,
    linkToFellowship,
    isBranchManagementPath,
    isEventPath,
    isEventsPath,
    isOrganizationPath,
    isOrganizationCharacterDemoPath,
    mechanicsSection,
    organizationSection,
    playerSection,
    reportsSection,
    characterId: characterIdInURL,
    playerId: playerIdInURL,
    branchSection,
    characterSection,
    charactersManagementSection,
    depositorySection,
    eventSection,
    organizationId,
  };
};

export default useAppNavigations;
