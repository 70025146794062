import { startCase } from 'lodash';
import { FC, ReactNode, useCallback, useState } from 'react';
import { IShiftKindEnum } from 'src/graphql/types';
import Input from 'src/components/0100_input';
import { useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';
import {
  ICreateEventShiftMutation,
  ICreateEventShiftMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { createEventShfit } from 'src/graphql/mutations/events.graphql';
import { useParams } from 'react-router-dom';
import usePermission from 'src/hooks/permissions/usePermissions';
import { sortFunctor } from 'src/utils/shiftTime';
import ResponseBox from 'src/components/0100_response_box';
import { faSortAlphaAsc } from '@fortawesome/free-solid-svg-icons';
import Shift, { TShift } from './Shift';

interface IProps {
  expandedShiftIds: number[];
  kind: IShiftKindEnum;
  shifts: TShift[];
  shiftTip: ReactNode | null;
  showInvisible: boolean;
  onExpand: ( id: number ) => void;
}

const ShiftKind: FC<IProps> = ({
  kind,
  expandedShiftIds,
  shifts,
  shiftTip,
  showInvisible,
  onExpand,
}) => {
  const { eventId } = useParams();
  const { buttonState } = useButtonStates();
  const [ isCreateSuccessful, setIsCreateSuccessful ] = useState( false );
  const { isPermitted: canManageShift } = usePermission({
    action: 'update_event_shift',
    eventId: Number( eventId ),
  });
  const {
    register,
    setFocus,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      label: '',
      baseRatio: '1',
    },
  });
  const { label, baseRatio } = watch();

  const [ createResult, create ] = useMutation<
    ICreateEventShiftMutation,
    ICreateEventShiftMutationVariables
  >( createEventShfit );

  const handleCreateShift = useCallback(() => {
    create({
      eventId: Number( eventId ),
      kind,
      label,
      baseRatio: Number( baseRatio ),
    }).then( res => {
      if ( res.data?.createEventShift?.event ) {
        setValue( 'label', '' );
        setIsCreateSuccessful( true );
        setFocus( 'label' );
        setTimeout(() => setIsCreateSuccessful( false ), 1000 );
      }
    });
  }, [ baseRatio, create, eventId, kind, label, setFocus, setValue ]);

  return (
    <>
      <tr>
        <td colSpan={5}>
          <div className="py-2 px-4 cyan-box flex items-center justify-between w-full">
            <div>
              {kind === IShiftKindEnum.Casting ? 'NPC' : startCase( kind )}
            </div>
          </div>
        </td>
      </tr>

      {canManageShift && showInvisible ? (
        <>
          <tr className="gray-box">
            <td />
            <td className="py-2">
              <Input
                fullWidth
                placeholder={
                  kind === 'casting'
                    ? 'Ex: Sat 2AM - 4AM'
                    : `Add ${startCase( kind )} Shift`
                }
                {...register( 'label', { required: true })}
              />
            </td>
            <td className="py-2 pl-2">
              <Input
                fullWidth
                className="text-right"
                {...register( 'baseRatio', { required: true })}
              />
            </td>
            <td colSpan={2} className="pt-2 pl-2">
              <Button
                className="w-full"
                defaultLabel="Create"
                state={buttonState({
                  isValid,
                  isDirty: true,
                  isFetching: createResult.fetching,
                  isSuccessful: isCreateSuccessful,
                })}
                stateLabel={{
                  loading: 'Creating...',
                  success: 'Created',
                }}
                onClick={() => isValid && handleCreateShift()}
              />
            </td>
          </tr>
          {shiftTip && (
            <tr>
              <td colSpan={5}>
                <ResponseBox type="neutral" withIcon={faSortAlphaAsc}>
                  {shiftTip}
                </ResponseBox>
              </td>
            </tr>
          )}
          <tr className="border-t-2 border-juno-cyan-400">
            <td className="pt-1.5" />
          </tr>
        </>
      ) : (
        <tr>
          <td className="pb-2" />
        </tr>
      )}

      {shifts.length > 0 ? (
        shifts
          .filter( x => ( showInvisible ? x : x.isEnabled ))
          .sort(( a, b ) => sortFunctor( a, b, kind ))
          .map( shift => (
            <Shift
              key={shift.id}
              shift={shift}
              canManageShift={canManageShift}
              isExpanded={expandedShiftIds.includes( shift.id )}
              onExpand={() => onExpand( shift.id )}
            />
          ))
      ) : (
        <tr>
          <td className="pb-2" colSpan={4}>
            No Shifts Available
          </td>
        </tr>
      )}
    </>
  );
};

export default ShiftKind;
