import { groupBy, startCase } from 'lodash';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import { ICharacterStatusEnum } from 'src/graphql/types';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';
import usePermission from 'src/hooks/permissions/usePermissions';
import ResponseBox from 'src/components/0100_response_box';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import CreateNewCharacter from './CreateNewCharacter';
import CharacterRow from './CharacterRow';

const SORT_ORDER = [
  ICharacterStatusEnum.Active,
  ICharacterStatusEnum.Staged,
  ICharacterStatusEnum.Retired,
  ICharacterStatusEnum.Inactive,
  ICharacterStatusEnum.Experimental,
];

const Characters: FC = () => {
  const { playerId } = useParams();
  const { isPermitted: canCreateNewCharacter } = usePermission({
    action: 'create_character',
    playerId: Number( playerId ),
  });
  const { isPermitted: canChangeCharacterStatus } = usePermission({
    action: 'update_player_data_sensitive',
    playerId: Number( playerId ),
  });

  const { player, characters, fetching, stale } =
    usePlayerWithCharactersInOrganization({
      playerId: Number( playerId ) || 0,
    });
  const { isPermitted: canRetireCharacter } = usePermission({
    action: 'retire_character',
    characterId: Number( player?.characters[0].id ),
  });
  const statusGroup = groupBy( characters, 'status' );

  return (
    <div>
      <Title
        variant="glass"
        title={player ? `#${player.id} - ${player.fullName}` : ''}
      >
        {`${characters.length}/${player?.maxCharactersCount ?? 0} Characters`}
      </Title>
      {!fetching ? (
        <div className="flex justify-center">
          <div className="max-w-[480px] w-full">
            {SORT_ORDER.map( status => (
              <div key={status} className="grid gap-2">
                <Title variant="heading">
                  <div className="flex justify-between items-center">
                    <div className="text-2xl">{startCase( status )}</div>
                    {status === ICharacterStatusEnum.Active &&
                      `${statusGroup[status]?.length ?? 0}/${player?.maxActiveCharactersCount ?? 0} limit`}
                    {status === ICharacterStatusEnum.Retired && (
                      <span className="text-juno-gray-200 text-sm">
                        Not counted against limit
                      </span>
                    )}
                  </div>
                </Title>
                {status === ICharacterStatusEnum.Active && (
                  <ResponseBox type="success" withIcon={faLightbulb}>
                    Only Active Characters can Check-in to Games and receive XP
                    from Membership purchases.
                  </ResponseBox>
                )}
                <div className="grid gap-2">
                  {statusGroup[status] ? (
                    statusGroup[status]
                      .sort(( a, b ) => a.name.localeCompare( b.name ))
                      .map( character => (
                        <CharacterRow
                          key={character.id}
                          character={character}
                          stale={stale}
                          canChangeCharacterStatus={canChangeCharacterStatus}
                          canDestroyCharacter={
                            canCreateNewCharacter &&
                            status === ICharacterStatusEnum.Staged
                          }
                          canRetireCharacter={canRetireCharacter}
                          canActivate={
                            ( statusGroup[ICharacterStatusEnum.Active]?.length ??
                              0 ) < ( player?.maxActiveCharactersCount ?? 0 )
                          }
                          branch={player?.userOrganization?.branch.name}
                        />
                      ))
                  ) : (
                    <div className="opacity-50">No Characters</div>
                  )}
                </div>
                {status === ICharacterStatusEnum.Staged &&
                  canCreateNewCharacter && <CreateNewCharacter />}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Characters;
