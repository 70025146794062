import { FC } from 'react';
import LoupeView, {
  TLoupeViewProps,
} from 'src/components/0500_blueprints/dystopia_rising/LoupeView';
import useOrganization from 'src/hooks/organizations/useOrganization';

interface IProps extends TLoupeViewProps {}

const Blueprints: FC<IProps> = props => {
  const { organization } = useOrganization();

  switch ( organization?.slug ) {
    case 'dystopia-rising':
      return <LoupeView {...props} />;
    default:
      return null;
  }
};

export default Blueprints;
