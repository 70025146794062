import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useSideSelect = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const handleChange = useCallback(
    ({ front, back }: { front: boolean; back: boolean }) => {
      if ( front && back ) {
        searchParams.set( 'sides', 'both' );
      } else if ( !front && back ) {
        searchParams.set( 'sides', 'back' );
      } else {
        searchParams.set( 'sides', 'front' );
      }

      setSearchParams( searchParams );
    },
    [ searchParams, setSearchParams ],
  );
  const printFront =
    searchParams.get( 'sides' ) === null ||
    [ 'front', 'both' ].includes( searchParams.get( 'sides' ) ?? '' );

  const printBack = [ 'back', 'both' ].includes( searchParams.get( 'sides' ) ?? '' );
  const printBoth = printFront && printBack;

  return {
    printFront,
    printBack,
    printBoth,
    handleChange,
  };
};

export default useSideSelect;
