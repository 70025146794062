import { gql } from 'urql';

export const getOrganizationBranches = gql`
  query GetOrganizationBranches($organizationId: Int, $owned: Boolean) {
    organization(organizationId: $organizationId) {
      id
      slug
      name
      branches(owned: $owned) {
        id
        region
        name
        shorthand
        website
        branchOwners {
          id
          user {
            id
            fullName
            emailAddress
          }
        }
      }
    }
  }
`;

export const getBranch = gql`
  query GetBranch($branchId: Int!) {
    branch(branchId: $branchId) {
      id
      name
      region
      district
      registrationUrl
      shorthand
      website
      locations {
        id
        name
        streetAddress
        city
        region
        zipCode
        existingEventsSize(branchId: $branchId)
      }
    }
  }
`;

export const getBranchStaffs = gql`
  query GetBranchStaffs($branchId: Int!) {
    branch(branchId: $branchId) {
      id
      name
      branchAssistants {
        id
        assistantFlags
        user {
          id
          fullName
          recentEventAttendances {
            id
            event {
              id
              name
              startsAt
              branch {
                id
                shorthand
              }
            }
          }
        }
      }
      branchOwners {
        id
        user {
          id
          fullName
          recentEventAttendances {
            id
            event {
              id
              name
              startsAt
              branch {
                id
                shorthand
              }
            }
          }
        }
      }
    }
  }
`;

export const getActivePlayers = gql`
  query GetActivePlayers($branchId: Int!) {
    branch(branchId: $branchId) {
      id
      activePlayers {
        id
        role
        branch {
          id
          shorthand
        }
        user {
          id
          fullName
          recentEventAttendances {
            id
            event {
              id
              name
              startsAt
              branch {
                id
                shorthand
              }
            }
          }
        }
      }
    }
  }
`;

export const getEventsInLocation = gql`
  query GetEventsInLocation($branchId: Int!, $locationId: Int!) {
    branch(branchId: $branchId) {
      id
      location(locationId: $locationId) {
        id
        events(branchId: $branchId) {
          id
          name
          startsAt
          eventAttendeesSize
        }
      }
    }
  }
`;

export const getBranchCaps = gql`
  query GetBranchCaps($branchId: Int!) {
    branch(branchId: $branchId) {
      id
      caps {
        id
        user {
          id
          fullName
        }
        grantor {
          id
          fullName
        }
        amount
        state
        reason
        createdAt
      }
    }
  }
`;

export const getBranchCorrectiveActions = gql`
  query GetBranchCorrectiveActions($branchId: Int!) {
    branch(branchId: $branchId) {
      id
      correctiveActions {
        id
        kind
        reason
        startsAt
        endsAt
        createdAt
        user {
          id
          fullName
        }
        issuer {
          id
          fullName
        }
      }
    }
  }
`;
