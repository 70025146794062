import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const BlueprintSidebar: FC = () => {
  const { linkToOrganization } = useAppNavigations();

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faLayerGroup} className="pr-2 fa-fw" />
          Blueprints
        </div>
      }
      to={linkToOrganization({ section: 'blueprints' })}
    />
  );
};

export default BlueprintSidebar;
