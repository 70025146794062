import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';

const CreateNewPlayerSidebar: FC = () => {
  const { organizationSlug } = useParams();

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faUserPlus} className="pr-2 fa-fw" />
          Create New Player
        </div>
      }
      to={`/${organizationSlug}/players/new`}
    />
  );
};

export default CreateNewPlayerSidebar;
