import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const EventCalendarSidebar: FC = () => {
  const { linkToEvents } = useAppNavigations();

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faCalendar} className="pr-2 fa-fw" />
          Event Calendar
        </div>
      }
      to={linkToEvents}
    />
  );
};

export default EventCalendarSidebar;
