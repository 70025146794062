import { FC, useCallback, useEffect, useMemo } from 'react';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import usePermission from 'src/hooks/permissions/usePermissions';
import ResponseBox from 'src/components/0100_response_box';
import Title from 'src/components/0100_title';
import Loading from 'src/components/0100_loading';
import Badge from 'src/components/0100_badge';
import {
  IAttendeeCharacterPrintRequestStatusEnum,
  ICharacterStatusEnum,
} from 'src/graphql/types';
import CharacterSelector from './CharacterSelector';
import TicketSelector from './TicketSelector';
import ShiftSelector from './ShiftSelector';
import Attendance from './Attendance';
import Execution from './Execution';
import CheckinBlocker from './CheckinBlocker';

interface IProps {
  forceSingleLine?: boolean;
}

const defaultValues = {
  characterIds: [] as number[],
  characterId: null as number | null,
  ticketId: null as number | null,
  buildGrowth: 0,
  printRequestOnCharacterIds: [] as number[],
  shiftIds: [] as number[],
  isPaid: false,
  isAttending: false,
};

const DystopiaRisingCheckinForm: FC<IProps> = ({ forceSingleLine }) => {
  const { event, eventAttendee, player, fetching, characters } =
    useEventAttendee();
  const activeCharacterIds = useMemo(
    () =>
      characters
        .filter( x => x.status === ICharacterStatusEnum.Active )
        .map( x => x.id ),
    [ characters ],
  );

  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number( event?.id ),
  });
  const methods = useForm({
    defaultValues,
  });
  const { reset, setValue } = methods;

  const resetData = useMemo(() => {
    if ( !eventAttendee ) {
      return defaultValues;
    }

    return {
      ticketId: eventAttendee.ticket.id,
      buildGrowth: eventAttendee.buildGrowth,
      printRequestOnCharacterIds: eventAttendee.attendeeCharacters
        .filter(
          x =>
            x.printRequestStatus ===
            IAttendeeCharacterPrintRequestStatusEnum.PrintRequested,
        )
        .map( x => x.character.id ),
      shiftIds: eventAttendee.shifts.map( x => x.id ),
      isPaid: eventAttendee.paid,
      isAttending: eventAttendee.attending,
    };
  }, [ eventAttendee ]);

  const postProcess = useCallback(() => {
    if ( !eventAttendee?.id ) {
      if ( activeCharacterIds.length > 0 ) {
        setValue( 'printRequestOnCharacterIds', activeCharacterIds );
      }
      if ( event?.activeMembership ) {
        setValue( 'characterIds', activeCharacterIds );
      }
    }

    if ( eventAttendee ) {
      setValue(
        'printRequestOnCharacterIds',
        eventAttendee.attendeeCharacters
          .filter( x => x.printRequestStatus === 'print_requested' )
          .map( x => x.character.id ) ?? [],
      );
      setValue(
        'characterIds',
        eventAttendee.attendeeCharacters.length === 0
          ? activeCharacterIds
          : eventAttendee.attendeeCharacters.map( x => x.character.id ),
      );

      if ( event?.activeMembership ) {
        setValue( 'characterId', null );
      } else if ( eventAttendee.attendeeCharacters[0]) {
        setValue(
          'characterId',
          eventAttendee.attendeeCharacters[0].character.id,
        );
      }
    }
  }, [ eventAttendee, activeCharacterIds, event?.activeMembership, setValue ]);

  useEffect(() => {
    reset( resetData );
  }, [ reset, resetData ]);

  useEffect(() => {
    postProcess();
  }, [ postProcess ]);

  if ( !player ) return null;
  if ( !canAssistCheckin && event?.canSelfCheckin === false ) {
    return (
      <ResponseBox type="error" withIcon={faTriangleExclamation}>
        Unable to Checkin to this Game.
        <br />
        Please contact your Game Runners for assistance.
      </ResponseBox>
    );
  }

  if ( fetching ) return <Loading />;

  return (
    <FormProvider {...methods}>
      <div className="grid gap-4 w-full">
        <Title title={player.fullName}>
          {`#${player.id}`}
          <Badge type={player.userOrganization?.role} />
          <div className="flex flex-col gap-1 sm:flex-row sm:gap-4 items-center justify-center">
            {eventAttendee && (
              <div className="cyan-box px-2 py-1 rounded mt-2 flex gap-2 items-center">
                <FontAwesomeIcon icon={faCheck} />
                Checked In
              </div>
            )}
            {eventAttendee?.checkinApproved && (
              <div className="green-box px-2 py-1 rounded mt-2 flex gap-2 items-center">
                <FontAwesomeIcon icon={faCheck} />
                Confirmed
              </div>
            )}
            {eventAttendee?.buildAwarded && (
              <div className="purple-box px-2 py-1 rounded mt-2 flex gap-2 items-center">
                <FontAwesomeIcon icon={faCheck} />
                XP Awarded
              </div>
            )}
          </div>
        </Title>
        <div className="flex justify-center w-full">
          <div className="grid gap-8 max-w-[480px] lg:max-w-[800px] w-full">
            <div className="auto-cols-max">
              {canAssistCheckin && <CheckinBlocker />}
            </div>
            <div
              className={clsx(
                'grid gap-8 items-start',
                forceSingleLine ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2',
              )}
            >
              <TicketSelector />
              <div>
                <Title variant="heading" title="Characters" />
                <CharacterSelector />
              </div>
            </div>
            <div
              className={clsx(
                'grid gap-8 items-start',
                forceSingleLine ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2',
              )}
            >
              <ShiftSelector />
            </div>
            <Attendance />
            <Execution />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default DystopiaRisingCheckinForm;
