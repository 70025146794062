import clsx from 'clsx';
import { getYear } from 'date-fns';
import { groupBy, pick, startCase } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import Loading from 'src/components/0100_loading';
import useCharacterXp from 'src/hooks/characters/useCharacterXp';
import CategoryButton from './CategoryButton';
import Viewer from './Viewer';

const nonGameSortOrder = [
  'membership',
  'referral',
  'retirement',
  'generic',
  'branch_transfer',
  'legacy',
  'penalty',
];
const categoriesWithoutXp = [ 'branch_transfer', 'legacy' ];

interface IProps extends ReturnType<typeof useCharacterXp> {}

const DystopiaRisingXpAudit: FC<IProps> = ({ xps, fetching, stale }) => {
  const [ activeGroup, setActiveGroup ] = useState<string | null>( null );

  const categoryGroups = useMemo(() => groupBy( xps, 'kind' ), [ xps ]);
  const games = useMemo(
    () =>
      pick( categoryGroups, [
        'home_game',
        'travel_game',
        'travel_as_home_game',
        'live',
        'branch_transfer',
      ]),
    [ categoryGroups ],
  );
  const gamesByYear = useMemo(
    () => groupBy( Object.values( games ).flat(), x => getYear( x.awardedAt )),
    [ games ],
  );

  const activeData = useMemo(
    () =>
      ( Number.isNaN( Number( activeGroup ))
        ? categoryGroups[activeGroup ?? '']
        : gamesByYear[activeGroup ?? 0]) ?? [],
    [ activeGroup, categoryGroups, gamesByYear ],
  );

  useEffect(() => {
    const years = Object.keys( gamesByYear );
    if ( activeGroup === null && years.length > 0 ) {
      setActiveGroup( years.sort(( a, b ) => b.localeCompare( a ))[0]);
    }
  }, [ activeGroup, gamesByYear ]);

  return (
    <div>
      <div
        className={clsx(
          'flex flex-wrap items-center sm:sticky top-0 glass-pane z-10 gap-4 py-2',
        )}
      >
        {Object.keys( gamesByYear )
          .sort(( a, b ) => b.localeCompare( a ))
          .map( year => (
            <CategoryButton
              key={year}
              label={year}
              value={
                gamesByYear[year]
                  .map( x => x.xpAwarded )
                  .reduce(( a, b ) => ( a ?? 0 ) + ( b ?? 0 ), 0 ) ?? 0
              }
              isActive={activeGroup === year}
              isLoading={fetching}
              onClick={() => setActiveGroup( year )}
            />
          ))}
        {nonGameSortOrder.map( category => (
          <CategoryButton
            key={category}
            label={startCase( category )}
            value={
              ( categoryGroups[category] ?? [])
                .map( x => x.xpAwarded )
                .reduce(( a, b ) => ( a ?? 0 ) + ( b ?? 0 ), 0 ) ?? 0
            }
            isActive={activeGroup === category}
            isLoading={fetching}
            displayXp={!categoriesWithoutXp.includes( category )}
            onClick={() => setActiveGroup( category )}
          />
        ))}
        {stale && <Loading size="small" />}
      </div>

      <Viewer
        data={activeData}
        splitByMonths={!nonGameSortOrder.includes( activeGroup ?? '' )}
      />
    </div>
  );
};

export default DystopiaRisingXpAudit;
