import { isAfter, parseISO } from 'date-fns';
import { FC } from 'react';
import Table from 'src/components/0100_table';
import Title from 'src/components/0100_title';
import useOrganizationSpecialDates from 'src/hooks/organizations/useOrganizationSpecialDates';
import Loading from 'src/components/0100_loading';
import usePermission from 'src/hooks/permissions/usePermissions';
import SpecialDate from './SpecialDate';

const SpecialDates: FC = () => {
  const { isPermitted: canAccess } = usePermission({
    action: 'network_tools',
  });
  const { isPermitted: canEdit } = usePermission({
    action: 'create_special_date',
  });
  const { specialDates, stale } = useOrganizationSpecialDates();

  if ( !canAccess ) return null;

  return (
    <div>
      <Title variant="glass" title="Special Dates" />
      <Table
        headers={[
          { content: 'Starts On', className: 'min-w-32' },
          { content: 'Ends On', className: 'min-w-32' },
          { content: 'Title', className: 'w-full' },
          { content: '', className: 'min-w-8' },
        ]}
      >
        {canEdit && (
          <>
            <SpecialDate canEdit={canEdit} />
            <tr className="border-b mt-[1px] border-juno-cyan-200">
              <td colSpan={4} />
            </tr>
            <tr className="border-t mt-[1px] border-juno-cyan-200">
              <td colSpan={4} />
            </tr>
          </>
        )}
        {stale && specialDates.length === 0 && (
          <tr>
            <td colSpan={4}>
              <Loading size="small" />
            </td>
          </tr>
        )}

        {specialDates
          .sort(( a, b ) =>
            isAfter( parseISO( a.startsAt ), parseISO( b.startsAt )) ? -1 : 1,
          )
          .map( x => (
            <SpecialDate
              key={x.id}
              id={x.id}
              canEdit={canEdit}
              startsAt={parseISO( x.startsAt )}
              endsAt={parseISO( x.endsAt )}
              title={x.title}
            />
          ))}
      </Table>
    </div>
  );
};

export default SpecialDates;
