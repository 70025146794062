import { addDays, isBefore, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import AugmentedInput from 'src/components/0200_augmented_input';
import EventCard from 'src/components/0200_event_card';
import { updateBranch } from 'src/graphql/mutations/branches.graphql';
import {
  IUpdateBranchMutation,
  IUpdateBranchMutationVariables,
} from 'src/graphql/mutations/branches.graphql.types';
import useEvents from 'src/hooks/events/useEvents';
import useBranch from 'src/hooks/organizations/branches/useBranch';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useMutation } from 'urql';

const Basics: FC = () => {
  const { data } = useBranch({});
  const { branchId } = useParams();
  const { events } = useEvents({
    start: addDays( new Date(), -30 ),
    end: addDays( new Date(), 720 ),
    branchIds: [ Number( data?.branch?.id ) ],
  });
  const { isPermitted } = usePermission({
    action: 'manage_branch',
    branchId: data?.branch?.id,
  });
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });
  const [ updatedFields, setUpdatedFields ] = useState<Record<string, boolean>>(
    {},
  );
  const {
    getValues,
    register,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: '',
      region: '',
      district: '' as string | null,
      registrationUrl: '' as string | null,
      shorthand: '',
      website: '' as string | null,
    },
  });

  const [ updateResult, update ] = useMutation<
    IUpdateBranchMutation,
    IUpdateBranchMutationVariables
  >( updateBranch );

  const handlePartialSubmit = useCallback(() => {
    const dirtyField = Object.keys( dirtyFields )[0] as keyof typeof dirtyFields;
    setUpdatedFields( prev => ({ ...prev, [dirtyField]: false }));
    update({
      branchId: data?.branch?.id ?? 0,
      [dirtyField]: getValues( dirtyField ),
    }).then( res => {
      if ( res.data?.updateBranch?.branch ) {
        setUpdatedFields( prev => ({ ...prev, [dirtyField]: true }));
      }
    });
  }, [ data?.branch?.id, dirtyFields, getValues, update ]);

  useEffect(() => {
    if ( data?.branch ) {
      reset( data.branch );
    }
  }, [ data?.branch, reset ]);

  useEffect(() => {
    setUpdatedFields({});
  }, [ branchId ]);

  return (
    <div className="grid gap-4">
      <div>
        <AugmentedInput
          title="Name"
          isLocked={!isPermitted}
          contentWhenLocked={data?.branch?.name}
          isBusy={updateResult.fetching && dirtyFields.name}
          isUpdated={updatedFields.name && !dirtyFields.name}
          {...register( 'name', { onBlur: handlePartialSubmit })}
        />
        <AugmentedInput isLocked title="Shorthand" {...register( 'shorthand' )} />
        <AugmentedInput isLocked title="Region" {...register( 'region' )} />
        <AugmentedInput
          isLocked={!canManageOrganization}
          title="District"
          placeholder="Ex: Hudson County"
          isBusy={updateResult.fetching && dirtyFields.district}
          isUpdated={updatedFields.district && !dirtyFields.district}
          {...register( 'district', { onBlur: handlePartialSubmit })}
        />
        <AugmentedInput
          width="w-[320px]"
          title="Registration URL"
          isLocked={!isPermitted}
          contentWhenLocked={data?.branch?.registrationUrl}
          isBusy={updateResult.fetching && dirtyFields.registrationUrl}
          isUpdated={
            updatedFields.registrationUrl && !dirtyFields.registrationUrl
          }
          {...register( 'registrationUrl', { onBlur: handlePartialSubmit })}
        />
        <AugmentedInput
          width="w-[320px]"
          title="Website"
          isLocked={!isPermitted}
          contentWhenLocked={data?.branch?.website}
          isBusy={updateResult.fetching && dirtyFields.website}
          isUpdated={updatedFields.website && !dirtyFields.website}
          {...register( 'website', { onBlur: handlePartialSubmit })}
        />
      </div>
      <div className="grid gap-2">
        {events
          .sort(( a, b ) =>
            isBefore( parseISO( a.startsAt ), parseISO( b.startsAt )) ? -1 : 1,
          )
          .map( x => (
            <div key={x.id}>
              <EventCard
                id={x.id}
                name={x.name}
                description={x.description}
                startsAt={parseISO( x.startsAt )}
                endsAt={parseISO( x.endsAt )}
                kind={x.kind}
                location={{
                  name: x.location.name,
                  streetAddress: x.location.streetAddress,
                  city: x.location.city,
                  region: x.location.region,
                  zipCode: x.location.zipCode,
                }}
                config={{
                  baseBuild: x.config.baseBuild,
                  maxExtraXp: x.config.maxExtraXp,
                  considersTravelersLocal: x.config.considersTravelersLocal,
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Basics;
