import { useDebouncedValue } from 'rooks';
import { getItemsWithDetails } from 'src/graphql/queries/items.graphql';
import {
  IGetItemsWithDetailsQuery,
  IGetItemsWithDetailsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { IItemKindEnum } from 'src/graphql/types';
import { useQuery } from 'urql';

const useItemsWithDetails = ({
  keyword,
  kind,
}: {
  keyword: string;
  kind?: IItemKindEnum;
}) => {
  const [ debouncedKeyword ] = useDebouncedValue( keyword, 100 );

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemsWithDetailsQuery,
    IGetItemsWithDetailsQueryVariables
  >({
    query: getItemsWithDetails,
    variables: {
      keyword: debouncedKeyword,
      kind,
    },
    requestPolicy: 'cache-and-network',
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItemsWithDetails;
