import { gql } from 'urql';

export const getItemKinds = gql`
  query GetItemKinds {
    organization {
      id
      itemKinds
    }
  }
`;

export const getItemGrades = gql`
  query GetItemGrades {
    organization {
      id
      itemGrades
    }
  }
`;

export const getItems = gql`
  query GetItems($keyword: String, $kind: ItemKindEnum) {
    organization {
      id
      items(versions: 4, keyword: $keyword, kind: $kind) {
        id
        name
        kind
        grade
        metadata {
          printHeader
          craftableItems {
            kind
          }
        }
      }
    }
  }
`;

export const getItemsWithDetails = gql`
  query GetItemsWithDetails($keyword: String, $kind: ItemKindEnum) {
    organization {
      id
      items(versions: 4, keyword: $keyword, kind: $kind) {
        id
        name
        kind
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
          craftingComponents {
            id
            component {
              id
              name
              grade
              kind
            }
            amount
          }
          craftingFinalProducts {
            id
            stack
            finalProduct {
              id
              name
              kind
              lifetimeAmount
              metadata {
                mechanics
                requirementsToUse
                uses
              }
              childItemClassifications {
                id
                childItem {
                  id
                  name
                  kind
                  lifetimeAmount
                  metadata {
                    mechanics
                    requirementsToUse
                    uses
                  }
                }
              }
            }
          }
        }
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
          itemReproductionComponents {
            id
            item {
              id
              name
            }
            amount
          }
        }
      }
    }
  }
`;

export const getItem = gql`
  query GetItem($itemId: Int!) {
    organization {
      id
      item(id: $itemId) {
        id
        name
        kind
        grade
        updatedAt
        lifetimeAmount
        lifetimeUnit
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
        blueprintForCraftings {
          id
          name
        }
        childItemClassifications {
          id
          childItem {
            id
            kind
            name
          }
        }
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
          craftingComponents {
            id
            component {
              id
              name
              grade
              kind
            }
            amount
          }
          craftingFinalProducts {
            id
            stack
            finalProduct {
              id
              name
              grade
              kind
              lifetimeAmount
              lifetimeUnit
              childItemClassifications {
                id
                childItem {
                  id
                  name
                  kind
                  lifetimeAmount
                  lifetimeUnit
                  metadata {
                    mechanics
                    requirementsToUse
                    uses
                  }
                }
              }
              metadata {
                mechanics
                notes
                printHeader
                requirementsToUse
                uses
                durationOfEffect
                validTargetDescription
                locationOfUse
                equipmentRequiredForUse
                durationOfRoleplay
                descriptionOfRoleplay
                activationRequirement
              }
            }
          }
        }
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
          itemReproductionComponents {
            id
            item {
              id
              name
              grade
              kind
            }
            amount
          }
        }
        parts {
          id
          amount
          itemCrafting {
            id
            blueprint {
              id
              name
            }
          }
        }
      }
    }
  }
`;
