import { FC, useState } from 'react';
import ResponseBox from 'src/components/0100_response_box';
import ItemSearch from 'src/components/0400_item_search';
import { createItemClassification } from 'src/graphql/mutations/items.graphql';
import {
  ICreateItemClassificationMutation,
  ICreateItemClassificationMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { useMutation } from 'urql';
import { IItemKindEnum } from 'src/graphql/types';
import ItemClassification, { TItemClassification } from './ItemClassification';

interface IProps {
  parentItemId: number;
  itemClassifications: TItemClassification[];
}

const ItemClassifications: FC<IProps> = ({
  parentItemId,
  itemClassifications,
}) => {
  const [ addChildItemError, setAddChildItemError ] = useState<string | null>(
    null,
  );
  const [ , create ] = useMutation<
    ICreateItemClassificationMutation,
    ICreateItemClassificationMutationVariables
  >( createItemClassification );

  return (
    <div className="border-b border-juno-gray-700">
      <div className="midtone-box p-2 border-b border-l-4 border-juno-gray-700">
        Classifications
      </div>
      <div>
        {itemClassifications
          .sort(( a, b ) => a.childItem.name.localeCompare( b.childItem.name ))
          .map( x => (
            <ItemClassification key={x.id} {...x} />
          ))}
        <div>
          <div className="px-2 py-2 border-l-4 border-juno-gray-700">
            <div className="flex items-center gap-4">
              <div className="whitespace-nowrap w-24">Add Item</div>
              <div className="w-full">
                <ItemSearch
                  kind={IItemKindEnum.CraftingInput}
                  onSearchResultClick={({ itemId }) => {
                    setAddChildItemError( null );
                    create({
                      parentItemId,
                      childItemId: itemId,
                    }).then( res => {
                      if ( res.data?.createItemClassification?.error ) {
                        setAddChildItemError(
                          res.data.createItemClassification.error,
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
            {addChildItemError && (
              <ResponseBox type="error">{addChildItemError}</ResponseBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemClassifications;
