import { gql } from 'urql';

export const createSpecialDate = gql`
  mutation CreateSpecialDate(
    $title: String!
    $startsAt: String!
    $endsAt: String!
  ) {
    createSpecialDate(
      input: {
        title: $title
        startsAt: $startsAt
        endsAt: $endsAt
        kind: calendar_block
      }
    ) {
      error
      organization {
        id
        specialDates {
          id
          title
          startsAt
          endsAt
          kind
        }
      }
    }
  }
`;

export const destroySpecialDate = gql`
  mutation DestroySpecialDate($id: Int!) {
    destroySpecialDate(input: { specialDateId: $id }) {
      error
      specialDate {
        id
      }
    }
  }
`;

export const updateSpecialDate = gql`
  mutation UpdateSpecialDate(
    $id: Int!
    $startsAt: String
    $endsAt: String
    $title: String
  ) {
    updateSpecialDate(
      input: {
        specialDateId: $id
        startsAt: $startsAt
        endsAt: $endsAt
        title: $title
      }
    ) {
      error
      specialDate {
        id
        title
        startsAt
        endsAt
        kind
      }
    }
  }
`;
