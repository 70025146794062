import { gql } from 'urql';

export const getPlayerWithOrganizations = gql`
  query GetPlayerWithOrganizations($playerId: Int!) {
    user(userId: $playerId) {
      id
      fullName
      firstName
      lastName
      preferredName
      emailAddress
      organizations {
        id
        name
        role
        slug
        shorthand
        branch {
          id
          name
          shorthand
        }
      }
    }
  }
`;

export const getPlayerWithCharactersInOrganization = gql`
  query GetPlayerWithCharactersInOrganization($playerId: Int!) {
    user(userId: $playerId) {
      id
      fullName
      firstName
      lastName
      preferredName
      emailAddress
      notes
      maxCharactersCount
      maxActiveCharactersCount
      lastMembership {
        id
        endsAt
      }
      userOrganization {
        id
        role
        assistantFlags
        branch {
          id
          name
          shorthand
        }
      }
      characters {
        id
        name
        status
        buildEarned
        buildUsed
        version
      }
      upcomingEvents {
        id
        checkinApproved
        buildAwarded
        event {
          id
          name
          startsAt
          location {
            id
            name
          }
        }
      }
    }
  }
`;

export const getPlayerPerks = gql`
  query GetPlayerPerks($playerId: Int!) {
    user(userId: $playerId) {
      id
      caps {
        id
        grantor {
          id
          fullName
        }
        branch {
          id
          shorthand
        }
        state
        reason
        amount
        createdAt
      }
    }
  }
`;

export const getPlayerCorrectiveActions = gql`
  query GetPlayerCorrectiveActions($playerId: Int!) {
    user(userId: $playerId) {
      id
      correctiveActions {
        id
        issuer {
          id
          fullName
        }
        branch {
          id
          shorthand
        }
        kind
        startsAt
        endsAt
        reason
      }
    }
  }
`;

export const getPlayerOwnedBranches = gql`
  query GetPlayerOwnedBranches($playerId: Int!) {
    user(userId: $playerId) {
      id
      ownedBranches {
        id
        name
      }
    }
  }
`;

export const getPlayerMemberships = gql`
  query GetPlayerMemberships($playerId: Int!) {
    user(userId: $playerId) {
      id
      memberships {
        id
        grantor {
          id
          fullName
        }
        grantingBranch {
          id
          shorthand
        }
        startsAt
        endsAt
      }
    }
  }
`;

export const getPlayerReferrals = gql`
  query GetPlayerReferrals($playerId: Int!) {
    user(userId: $playerId) {
      id
      invitations {
        id
        createdAt
        newbie {
          id
          fullName
        }
        inviter {
          id
          fullName
        }
      }
      referrals {
        id
        createdAt
        newbie {
          id
          fullName
        }
        inviter {
          id
          fullName
        }
      }
    }
  }
`;

export const getPlayerAttendances = gql`
  query GetPlayerAttendances($playerId: Int!, $from: String, $upto: String) {
    user(userId: $playerId) {
      id
      eventAttendances(from: $from, upto: $upto) {
        id
        buildAwarded
        buildGrowth
        checkinApproved
        kind
        paid
        attending
        attendeeCharacters {
          id
          buildGrowth
          character {
            id
            name
          }
        }
        event {
          id
          name
          startsAt
          endsAt
          registrationOpensAt
          kind
          isVirtual
          branch {
            id
            shorthand
            organization {
              id
              slug
            }
          }
        }
      }
    }
  }
`;

export const searchPlayers = gql`
  query SearchPlayer(
    $query: String!
    $playersOnly: Boolean
    $accessLevelFilters: [OrganizationRoleEnum!]
    $branchId: Int
  ) {
    searchPlayer(
      query: $query
      playersOnly: $playersOnly
      accessLevelFilters: $accessLevelFilters
      branchId: $branchId
    ) {
      ... on User {
        __typename
        id
        firstName
        lastName
        fullName
        preferredName
        characters(checkinableOnly: true) {
          id
          name
        }
        userOrganization {
          id
          role
          assistantFlags
        }
      }
      ... on Character {
        __typename
        id
        name
        user {
          id
          firstName
          lastName
          fullName
          preferredName
          userOrganization {
            id
            role
            assistantFlags
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;
