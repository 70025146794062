import { FC, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { startCase } from 'lodash';
import { faChevronDown, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Input from 'src/components/0100_input';
import { ITicket } from 'src/graphql/types';

import {
  IUpdateEventTicketMutation,
  IUpdateEventTicketMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { updateEventTicket } from 'src/graphql/mutations/events.graphql';
import TicketAttendees, { TOnFilterChange } from './TicketAttendees';
import s from './index.module.scss';

export type TTicket = Pick<
  ITicket,
  | 'id'
  | 'label'
  | 'cost'
  | 'limit'
  | 'eventAttendeesCount'
  | 'shiftResponsibilities'
  | 'fixedLimit'
  | 'passType'
  | 'restrictionType'
>;
interface IProps {
  ticket: TTicket;
  isExpanded: boolean;
  onExpand: () => void;
  onFilterChange?: ({ length, hasSearchQuery }: TOnFilterChange ) => void;
}

const Ticket: FC<IProps> = ({
  ticket,
  isExpanded,
  onExpand,
  onFilterChange,
}) => {
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState( false );
  const [ hasSearchQuery, setHasSearchQuery ] = useState( false );
  const [ searchMatchesLength, setSearchMatchesLength ] = useState( 0 );
  const hasAttendees =
    !hasSearchQuery || ( hasSearchQuery && searchMatchesLength > 0 );
  const {
    register,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      label: '',
      cost: '0',
      limit: undefined as string | undefined,
    },
  });

  const { label, cost, limit } = watch();

  const [ , update ] = useMutation<
    IUpdateEventTicketMutation,
    IUpdateEventTicketMutationVariables
  >( updateEventTicket );

  const handleUpdateTicket = useCallback(() => {
    if ( Object.keys( dirtyFields ).length === 0 ) return;

    update({
      ticketId: ticket.id,
      label,
      cost: Number( cost ),
      limit: limit?.trim() === '' ? undefined : Number( limit ),
      unlimited: limit?.trim() === '',
    }).then( res => {
      if ( res.data?.updateEventTicket?.ticket ) {
        setIsUpdateSuccessful( true );
        setTimeout(() => setIsUpdateSuccessful( false ), 1000 );
      }
    });
  }, [ cost, dirtyFields, label, limit, ticket.id, update ]);

  const handleFilterChange = useCallback(
    ({ length, hasSearchQuery }: TOnFilterChange ) => {
      setHasSearchQuery( hasSearchQuery );
      setSearchMatchesLength( length );
      onFilterChange?.({
        length,
        hasSearchQuery,
        identifier: String( ticket.id ),
      });
    },
    [ onFilterChange, ticket.id ],
  );

  useEffect(() => {
    if ( ticket ) {
      reset({
        label: ticket.label,
        cost: String( ticket.cost ),
        limit: ticket.limit === null ? undefined : String( ticket.limit ),
      });
    }
  }, [ reset, ticket ]);

  return (
    <>
      <tr
        className={clsx(
          'transition-all ease-in-out',
          isUpdateSuccessful ? 'bg-lime-600/75' : 'bg-transparent',
          hasAttendees ? 'opacity-100' : 'opacity-50',
        )}
      >
        <td className="pb-2">
          <div className="flex items-center w-full">
            <Input
              fullWidth
              placeholder="Create New Ticket"
              {...register( 'label' )}
              onBlur={handleUpdateTicket}
              onEnter={handleUpdateTicket}
            />
            <div className="relative">
              <div className="absolute right-2 -top-3 whitespace-nowrap text-right text-juno-gray-700 flex items-center gap-1">
                {[ 'assistant_restricted', 'employee_restricted' ].includes(
                  ticket.restrictionType,
                ) ? (
                  'Flex'
                ) : (
                  <>
                    <div>{ticket?.shiftResponsibilities}</div>
                    <FontAwesomeIcon icon={faStopwatch} />
                  </>
                )}
              </div>
            </div>
          </div>
          {ticket.passType !== 'event_pass' && (
            <div className="flex justify-between">
              <div />
              <div
                className={clsx(
                  'text-right uppercase text-sm px-4 opacity-50 bg-juno-gray-700 mr-1 whitespace-nowrap overflow-hidden',
                  s.clipped,
                )}
              >
                {startCase( ticket.passType )}
              </div>
            </div>
          )}
        </td>
        <td className="pb-2 pl-2">
          <Input
            fullWidth
            type="number"
            min={0}
            step={0}
            max={999}
            className="text-right"
            {...register( 'cost' )}
            onBlur={handleUpdateTicket}
            onEnter={handleUpdateTicket}
          />
        </td>
        <td className="pb-2 pl-2">
          <Input
            fullWidth
            type="number"
            disabled={ticket && !!ticket.fixedLimit}
            className="text-right"
            placeholder="∞"
            min={0}
            step={0}
            max={999}
            {...register( 'limit' )}
            onBlur={handleUpdateTicket}
            onEnter={handleUpdateTicket}
          />
        </td>
        <td className="pr-2">
          <button
            type="button"
            className="w-full pt-[5px]"
            onClick={() => Number( ticket.eventAttendeesCount ) > 0 && onExpand()}
          >
            {hasSearchQuery ? (
              <div className="flex justify-end items-center">
                {`${searchMatchesLength}/${ticket.eventAttendeesCount ?? 0}`}
              </div>
            ) : (
              <div className="flex items-center justify-end gap-2">
                {ticket?.eventAttendeesCount}
                {Number( ticket.eventAttendeesCount ) > 0 && (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx(
                      'transition-all',
                      isExpanded && '-scale-y-100',
                    )}
                  />
                )}
              </div>
            )}
          </button>
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          <TicketAttendees
            ticketId={ticket.id}
            isExpanded={isExpanded}
            onFilterChange={handleFilterChange}
          />
        </td>
      </tr>
    </>
  );
};

export default Ticket;
