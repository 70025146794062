import { gql } from 'urql';

export const updateBranch = gql`
  mutation UpdateBranch(
    $branchId: Int!
    $name: String
    $website: String
    $registrationUrl: String
    $district: String
  ) {
    updateBranch(
      input: {
        branchId: $branchId
        name: $name
        website: $website
        registrationUrl: $registrationUrl
        district: $district
      }
    ) {
      error
      branch {
        id
        name
        region
        district
        registrationUrl
        shorthand
        website
      }
    }
  }
`;

export const assignBranchLocation = gql`
  mutation AssignBranchLocation(
    $branchId: Int!
    $locationId: Int!
    $forRemoval: Boolean
  ) {
    assignBranchLocation(
      input: {
        branchId: $branchId
        locationId: $locationId
        forRemoval: $forRemoval
      }
    ) {
      error
      branch {
        id
        locations {
          id
          name
          streetAddress
          city
          region
          zipCode
          existingEventsSize(branchId: $branchId)
        }
      }
    }
  }
`;

export const assignBranchOwnership = gql`
  mutation AssignBranchOwnership($branchId: Int!, $playerId: Int!) {
    assignBranchOwnership(input: { branchId: $branchId, playerId: $playerId }) {
      error
      branch {
        id
        branchAssistants {
          id
          assistantFlags
          user {
            id
            fullName
            recentEventAttendances {
              id
              event {
                id
                name
                startsAt
                branch {
                  id
                  shorthand
                }
              }
            }
          }
        }
        branchOwners {
          id
          user {
            id
            fullName
            recentEventAttendances {
              id
              event {
                id
                name
                startsAt
                branch {
                  id
                  shorthand
                }
              }
            }
          }
        }
      }
    }
  }
`;
