import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';

interface IProps {
  limit: number;
  nominal: number;
  highlightClassName: string;
  mutedClassName: string;
  animate?: boolean;
}

const ProgressBar: FC<IProps> = ({
  limit,
  nominal,
  highlightClassName,
  mutedClassName,
  animate,
}) => (
  <div>
    <div className={clsx( 'w-full h-[1px] border rounded', mutedClassName )} />
    <motion.div
      className={clsx(
        'w-full h-[1px] border -mt-[2px] rounded',
        highlightClassName,
      )}
      initial={{ width: 0 }}
      animate={{
        width: `${( nominal * 100 ) / limit}%`,
        transition: {
          duration: animate ? 0.5 : 0,
          delay: animate ? 0.25 : 0,
          ease: [ 0.5, 0.2, 0.5, 0.8 ],
        },
      }}
    />
  </div>
);

export default ProgressBar;
